import { API } from '../../const';
import { put, takeEvery, call } from 'redux-saga/effects';
import api from '../../services/api';
import axios from '../../services/axios';
import actionTypes from './auth.types';

export function* doSignIn(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.post(`${API.SIGNIN}?location=${data.location}`, data.params));
    if (res.status === 200) {
      axios.defaults.headers.common.Authorization = res.data.data.token;
      if (res?.data?.data?.user_branch_type === 'center_user') {
        localStorage.setItem('accessToken', res.data.data.token);
        localStorage.setItem('refreshToken', res.data.data.refreshToken);
      }
      yield put({ type: actionTypes.SET_SIGNIN, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (error) {
    const { cbError } = action;
    const alert = error?.response?.data?.message || '';
    cbError(alert);
  }
}
export function* doForgetPassword(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() =>
      api.post(`${API.FORGOT_PASSWORD}?location=${data.location}`, data.params)
    );
    if (res.status === 200) {
      axios.defaults.headers.common.Authorization = res.data.data.token;
      yield put({ type: actionTypes.SET_FORGET_PASSWORD, payload: res.data.data });
      cb();
    }
  } catch (error) {
    const { cbError } = action;
    const alert = error?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* doValidateOTP(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.post(API.VALIDATE_OTP, data));
    if (res.status === 200) {
      localStorage.setItem('resetToken', res.data.data.token);
      axios.defaults.headers.common.Authorization = res.data.data.token;
      cb();
    }
  } catch (error) {
    const { cbError } = action;
    const alert = error?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* doReSetPassword(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.patch(API.RESET_PASSWORD, data));
    if (res.status === 200) {
      cb();
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* doResendCode(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.post(API.RESEND_CODE, data));
    if (res.status === 200) {
      cb();
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* doSetPassword(action) {
  try {
    const { payload, cb } = action;
    const { token, password } = payload;
    const res = yield call(() => api.put(`${API.SET_PASSWORD}?token=${token}`, { password }));
    if (res.status === 200) {
      yield put({ type: actionTypes.SET_SET_PASSWORD, payload: res.data.data });
      cb(res);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* doChangePassword(action) {
  const { payload: data, cb } = action;
  try {
    const res = yield call(() => api.patch(API.CHANGE_PASSWORD, data));
    if (res.status === 200) {
      cb();
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* doRefreshToken(action) {
  const { payload: data, cb } = action;
  try {
    const res = yield call(() =>
      api.get(`${API.REFRESH_TOKEN}?refresh_token=${data?.refreshToken}`)
    );
    if (res.status === 200) {
      axios.defaults.headers.common.Authorization = res.data.data.token;
      localStorage.setItem('accessToken', res.data.data.token);
      yield put({ type: actionTypes.SET_REFRESH_TOKEN, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* watchAuthSaga() {
  yield takeEvery(actionTypes.DO_SIGNIN, doSignIn);
  yield takeEvery(actionTypes.DO_FORGET_PASSWORD, doForgetPassword);
  yield takeEvery(actionTypes.DO_RESET_PASSWORD, doReSetPassword);
  yield takeEvery(actionTypes.DO_SET_PASSWORD, doSetPassword);
  yield takeEvery(actionTypes.DO_CHANGE_PASSWORD, doChangePassword);
  yield takeEvery(actionTypes.VALIDATE_OTP, doValidateOTP);
  yield takeEvery(actionTypes.RESEND_CODE, doResendCode);
  yield takeEvery(actionTypes.DO_REFRESH_TOKEN, doRefreshToken);
}

export default watchAuthSaga;
