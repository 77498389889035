export default {
  // auth
  SIGNIN: 'auth/login',
  FORGOT_PASSWORD: '/auth/forgot-password',
  RESET_PASSWORD: 'auth/reset-password',
  SET_PASSWORD: 'auth/set-password',
  VALIDATE_OTP: 'auth/validate-otp',
  RESEND_CODE: 'auth/resend-otp',
  REFRESH_TOKEN: '/auth/refresh-token',

  // profile
  USER_PROFILE: 'user/profile',
  CHANGE_PASSWORD: '/auth/change-password',

  //role
  ROLE: '/role',
  ROLEDETAIL: '/role/details',

  //patient
  PATIENT: '/patient',
  PATIENTBRANCH: '/patient/branch',
  PRICE: '/patient/price-calculator',
  CREATEAPPOINTMENT: '/patient/appointments',

  //audits
  FETCH_AUDITS: '/settings/audit',

  //user
  USER: '/user',
  BRANCH: '/branch',

  //referrals
  FETCH_REFERRALS: '/referee',
  BRANCHPATIENTSRECORD: 'patient/records/branch',
  VALIDATE_PATIENT_OTP: '/patient/validate-otp',
  REFEREE_INSTITUTION: '/referee/referee-institutions',

  // partners
  PARTNERSHIP_TYPE: '/partners/partnership-type',
  PARTNERS: '/partners',

  // Referral
  REFERRAL: '/referee',

  // Packages
  MULTIPLEPACKAGE: 'package/multiple',
  PACKAGE: 'package',

  // Procedure
  PROCEDURES: '/procedure/tests',
  PRICE_TYPES: '/procedure/pricing-types',

  //Settings
  SETTINGS: '/settings',
  FETCH_ADMIN_DASHBOARD: 'analytics/center-dashboard',

  //Support
  SUPPORT: '/support',

  // Overview
  ANALYTICS: '/analytics',

  // Bill dashboard
  BILLING: '/analytics',

  //procedure
  FETCH_PROCEDURE: '/procedure',
  
  // online booking
  ONLINEBOOKING: 'web/visit-enquiry-list',
  ONLINEBOOKINGDETAILS: 'web/visit-enquiry',
  INVOICES:  'settings/all-invoices'
};
