import clinicianTypes from './clinicians.types';

export const getWaitListAction = (payload, cb, cbError) => ({
  type: clinicianTypes.GET_PATIENTS_PROCEDURES,
  payload,
  cb,
  cbError
});

export const getPatientHistoryByRoomAction = (payload, cb, cbError) => ({
  type: clinicianTypes.GET_PATIENTS_VISITS,
  payload,
  cb,
  cbError
});

export const getSinglePatientHistoryAction = (payload, cb, cbError) => ({
  type: clinicianTypes.GET_SINGLE_VISIT,
  payload,
  cb,
  cbError
});

export const getTestComponentAction = (payload, cb, cbError) => ({
  type: clinicianTypes.GET_TEST_COMPONENT,
  payload,
  cb,
  cbError
});

export const getPatientTestResultsAction = (payload, cb, cbError) => ({
  type: clinicianTypes.GET_TEST_RESULT,
  payload,
  cb,
  cbError
});

export const getSinglePatientVisitTestAction = (payload, cb, cbError) => ({
  type: clinicianTypes.GET_TEST_DETAILS,
  payload,
  cb,
  cbError
});

export const doCheckInPatientAction = (payload, cb, cbError) => ({
  type: clinicianTypes.CHECK_IN_PATIENT,
  payload,
  cb,
  cbError
});

export const doTransferTestAction = (payload, cb, cbError) => ({
  type: clinicianTypes.TRANSFER_TEST,
  payload,
  cb,
  cbError
});

export const getIncomingPatientsAction = (payload, cb, cbError) => ({
  type: clinicianTypes.GET_INCOMING_PATIENTS,
  payload,
  cb,
  cbError
});

export const getIncomingTestsAction = (payload, cb, cbError) => ({
  type: clinicianTypes.GET_INCOMING_TESTS,
  payload,
  cb,
  cbError
});
