import axios from 'axios';
import NProgress from 'nprogress';
import { isAuthenticated } from '../helpers/isAuthenticated';
import { customHistory } from '../utils/customBrowserRouter';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_MDAAS_CENTERS_BACKEND_API_URL}/v1/`,
  timeout: 0,
  headers: {
    'Content-Type': 'application/json'
  }
});
instance.interceptors.request.use((config) => {
  if (isAuthenticated() || config.url.includes('auth')) {
    const access_token = localStorage.getItem('accessToken');
    const reset_token = localStorage.getItem('resetToken');

    config.headers.Authorization = access_token || reset_token;
    NProgress.start();
  } else {
    customHistory.push('/auth/sign-in');
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  (error) => {
    NProgress.done();

    if (error.code === 'ECONNABORTED') {
      // TODO: Add toast message
      return error;
    }

    const originalConfig = error.config;
    if (error.response) {
      if (error.response.status === 401 && error.config && !originalConfig._retry) {
        originalConfig._retry = true;

        customHistory.push('/auth/sign-in');
        if (error.response.status === 500) {
          // TODO: handle server error
        }
      }
    }
    return Promise.reject(error);
  }
);
export default instance;
