import userTypes from './user.types';

export const getAllUsersAction = (payload, cb, cbError) => ({
  type: userTypes.GET_ALL_USER,
  payload,
  cb,
  cbError
});


