import paymentTypes from './payment.types';

export const getPaymentRecordsAction = (payload, cb, cbError) => ({
  type: paymentTypes.GET_PAYMENT_RECORD,
  payload,
  cb,
  cbError
});

export const getPaymentTrackerAction = (payload, cb, cbError) => ({
  type: paymentTypes.PAYMENT_TRACKER,
  payload,
  cb,
  cbError
});

export const getPaymentDetailsAction = (payload, cb, cbError) => ({
  type: paymentTypes.PAYMENT_DETAILS,
  payload,
  cb,
  cbError
});

export const editPaymentAction = (payload, cb, cbError) => ({
  type: paymentTypes.UPDATE_PAYMENT,
  payload,
  cb,
  cbError
});

export const recordPaymentAction = (payload, cb, cbError) => ({
  type: paymentTypes.RECORD_PAYMENT,
  payload,
  cb,
  cbError
});

export const approveBonusRequestAction = (payload, cb, cbError) => ({
  type: paymentTypes.APPROVE_BONUS_REQUEST,
  payload,
  cb,
  cbError
});

export const sendBillToPartnerAction = (payload, cb, cbError) => ({
  type: paymentTypes.SEND_PARTNER_BILL,
  payload,
  cb,
  cbError
});

export const sendRecordsToClinicianAction = (payload, cb, cbError) => ({
  type: paymentTypes.SEND_RECORDS_TO_CLINICAN,
  payload,
  cb,
  cbError
});

export const requestDiscountApprovalAction = (payload, cb, cbError) => ({
  type: paymentTypes.DISCOUNT_APPROVAL,
  payload,
  cb,
  cbError
});

export const getTransactionTabAction = (payload, cb, cbError) => ({
  type: paymentTypes.GET_TRANSACTION_TAB,
  payload,
  cb,
  cbError
});

export const createReceiptAction = (payload, cb, cbError) => ({
  type: paymentTypes.CREATE_RECEIPT,
  payload,
  cb,
  cbError
});

export const getSingleReceiptAction = (payload, cb, cbError) => ({
  type: paymentTypes.GET_SINGLE_RECEIPT,
  payload,
  cb,
  cbError
});

export const getPartnerPatientReceiptDataAction = (payload, cb, cbError) => ({
  type: paymentTypes.GET_PARTNER_PATIENT_RECEIPT_DATA,
  payload,
  cb,
  cbError
});

export const getPatientReceiptDataAction = (payload, cb, cbError) => ({
  type: paymentTypes.GET_PATIENT_RECEIPT_DATA,
  payload,
  cb,
  cbError
});
