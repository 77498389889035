import React from 'react';
import Loader from '../Loader';
import styles from './button.module.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Button = ({
  type,
  theme,
  variant,
  wide,
  size,
  onClick,
  onClose,
  disabled,
  loading,
  width,
  children,
  dataTestId
}) => {
  return (
    <button
      style={{ width: `${width}px` }}
      className={classnames(
        styles.btn,
        styles[`btn__${theme}`],
        styles[`${variant}`],
        wide && styles.wide,
        styles[`btn__${size}`]
      )}
      type={type}
      theme={theme}
      variant={variant}
      wide={wide}
      size={size}
      onClick={onClick}
      onClose={onClose}
      disabled={disabled}
      data-testid={dataTestId}>
      {loading ? <Loader /> : children}
    </button>
  );
};

Button.defaultProps = {
  size: '',
  onClick: undefined,
  theme: 'primary' | 'secondary' | 'plain' | 'default',
  width: null,
  children: React.ReactNode,
  type: null
};

Button.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.string.isRequired,
  type: PropTypes.string,
  variant: PropTypes.string,
  wide: PropTypes.bool,
  size: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  width: PropTypes.string,
  disabled: PropTypes.bool || PropTypes.string,
  dataTestId: PropTypes.string
};

export default Button;
