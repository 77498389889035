import patientTypes from './patient.types';

export const initialState = {
  singlePatient: {},
  patients: [],
  allPatients: [],
  branchRecord: [],
  referral: [],
  procedures: [],
  savedProcedures: [],
  multiplePackage: [],
  patientReferalID: []
};

const patientReducers = (state = initialState, action) => {
  switch (action.type) {
    case patientTypes.SET_PATIENTS:
      return {
        ...state,
        patients: action.payload
      };
    case patientTypes.SET_ALL_PATIENTS:
      return {
        ...state,
        allPatients: action.payload
      };
    case patientTypes.SET_SINGLE_PATIENT:
      return {
        ...state,
        singlePatient: action.payload
      };
    case patientTypes.SET_BRANCH_PATIENTS:
      return {
        ...state,
        branchRecord: action.payload
      };
    case patientTypes.SET_REFERRAL:
      return {
        ...state,
        referral: action.payload
      };
    case patientTypes.SET_PROCEDURES:
      return {
        ...state,
        procedures: action.payload
      };
    case patientTypes.SAVE_PROCEDURE_SELECTED:
      return {
        ...state,
        savedProcedures: action.payload
      };
    case patientTypes.SET_PACKAGE_DETAILS:
      return {
        ...state,
        multiplePackage: action.payload
      };
    case patientTypes.SAVE_REFERRAL_PATIENT_ID:
      return {
        ...state,
        patientReferalID: action.payload
      };
    default:
      return state;
  }
};

export default patientReducers;
