import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from 'router/PrivateRoute';

export const ReferralsPage = lazy(() => import('pages/Referrals/Referrals'));
export const CreateReferralPage = lazy(() => import('container/Referral/createReferral'));
export const EditReferralPage = lazy(() => import('container/Referral/editReferral'));
export const ReferralDetailsPage = lazy(() => import('container/Referral/referralDetails'));
export const ViewPaymentDetailsPage = lazy(() => import('container/Referral/viewPaymentDetails'));
export const NewVisitRefferalSourcePage = lazy(() =>
  import('container/Patient/NewVisitRefferalSource')
);

export default function ReferralRoutes() {
  return (
    <Routes>
      <Route
        path="/referrals"
        element={
          <PrivateRoute>
            <ReferralsPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/referrals/create"
        element={
          <PrivateRoute>
            <CreateReferralPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/referrals/edit/:id/:page"
        element={
          <PrivateRoute>
            <EditReferralPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/referrals/:id/:page"
        element={
          <PrivateRoute>
            <ReferralDetailsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/referrals/payment/:paymentId/:id"
        element={
          <PrivateRoute>
            <ViewPaymentDetailsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/referrals/payment/:hasPaid/:id"
        element={
          <PrivateRoute>
            <ViewPaymentDetailsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/new-visit/referal-source"
        element={
          <PrivateRoute>
            <NewVisitRefferalSourcePage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
