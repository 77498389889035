const queryFormatter = (parameters) => {
  if (parameters) {
    return Object.keys(parameters)
      .map((key) => {
        const value = parameters[key];
        return value !== null && value !== undefined && value !== '' ? `${key}=${value}` : '';
      })
      .filter(Boolean)
      .join('&');
  }
  return '';
};
export default queryFormatter;
