import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from 'router/PrivateRoute';
import EditVisitRefferalSource from 'container/FrontDesk/EditVisitReferralSource';

export const ChooseProcedurePage = lazy(() => import('pages/FrontDesk/ChooseProcedure'));
export const PriceCalculatorPage = lazy(() => import('pages/FrontDesk/PriceCalculator'));
export const SummaryPage = lazy(() => import('pages/FrontDesk/Summary'));
export const PackagesPage = lazy(() => import('pages/FrontDesk/Packages'));

export default function FrontDeskRoutes() {
  return (
    <Routes>
      <Route
        path="/front-desk/price-calculator"
        element={
          <PrivateRoute>
            <PriceCalculatorPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/front-desk/summary"
        element={
          <PrivateRoute>
            <SummaryPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/front-desk/choose-procedure"
        element={
          <PrivateRoute>
            <ChooseProcedurePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/front-desk/packages"
        element={
          <PrivateRoute>
            <PackagesPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/edit-visit/referal-source/:id/:page"
        element={
          <PrivateRoute>
            <EditVisitRefferalSource />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
