import notificationTypes from './notification.types';

export const initialState = {
  notification: {}
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationTypes.SAVE_SINGLE_NOTIFICATION:
      return {
        ...state,
        notification: action.payload
      };

    default:
      return state;
  }
};

export default notificationReducer;
