import React from 'react';
import styles from './gridloader.module.scss';

const SinglePageLoader = () => (
  <div className={styles.skeleton__singlepage}>
    {Array.from({ length: 12 }, (_, i) => {
      return <div className={styles.skeleton__singlepage__item} key={i} />;
    })}
  </div>
);

export default SinglePageLoader;
