import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { customHistory } from 'utils/customBrowserRouter';
import { isAuthenticated } from 'helpers/isAuthenticated';
import { doRefreshTokenAction } from 're-ducks/auth';
import { store } from 'index';

const PrivateRoute = ({ children }) => {
  const getToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');

  if (isAuthenticated() === false) {
    return store.dispatch(
      doRefreshTokenAction(
        { refreshToken },
        () => {
          return window.location.reload();
        },
        (error) => {
          if (error.response?.status === 401) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('code');
            localStorage.removeItem('persist:root');
            <Navigate to="/auth/sign-in" />;
            return window.location.reload();
          }
        }
      )
    );
  } else if (getToken === null || undefined) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('code');
    localStorage.removeItem('persist:root');
    return customHistory.push('/auth/sign-in');
  } else {
    return <>{children}</>;
  }
};

PrivateRoute.propTypes = {
  children: PropTypes.oneOf([PropTypes.ReactNode, PropTypes.func])
};

export default PrivateRoute;
