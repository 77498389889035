import { API } from 'const';
import queryFormatter from 'helpers/queryFormatter';
import { call, put, takeEvery } from 'redux-saga/effects';
import api from 'services/api';
import auditTypes from './audit.types';

export function* getAuditTrail(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.FETCH_AUDITS}?${queryFormatter(data.params)}`));
    if (res.status === 200) {
      yield put({ type: auditTypes.SET_ALL_AUDITS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getAuditDetails(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.FETCH_AUDITS}/${data.id}`));
    if (res.status === 200) {
      yield put({ type: auditTypes.SET_AUDIT_DETAILS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getAuditLogs(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.FETCH_AUDITS}/${data.id}/activity?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: auditTypes.SET_AUDIT_LOGS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* watchAuditSaga() {
  yield takeEvery(auditTypes.GET_ALL_AUDITS, getAuditTrail);
  yield takeEvery(auditTypes.GET_AUDIT_DETAILS, getAuditDetails);
  yield takeEvery(auditTypes.GET_AUDIT_LOGS, getAuditLogs);
}

export default watchAuditSaga;
