import referralTypes from './referral.types';

export const getReferralAction = (payload, cb, cbError) => ({
  type: referralTypes.FETCH_REFERRALS,
  payload,
  cb,
  cbError
});

export const createReferralAction = (payload, cb, cbError) => ({
  type: referralTypes.CREATE_REFERRAL,
  payload,
  cb,
  cbError
});

export const getSingleReferralAction = (payload, cb, cbError) => ({
  type: referralTypes.FETCH_SINGLE_REFERRAL,
  payload,
  cb,
  cbError
});

export const editSingleReferralAction = (payload, cb, cbError) => ({
  type: referralTypes.EDIT_REFERRAL,
  payload,
  cb,
  cbError
});

export const fetchBankAction = (cb, cbError) => ({
  type: referralTypes.FETCH_BANKS,
  cb,
  cbError
});

export const getRefereeInstitutions = (payload, cb, cbError) => ({
  type: referralTypes.GET_REFEREE_INSTITUTION,
  payload,
  cb,
  cbError
});

export const getDuePaymentAction = (payload, cb, cbError) => ({
  type: referralTypes.GET_DUE_PAYMENT,
  payload,
  cb,
  cbError
});

export const getSingleRefereePaymentAction = (payload, cb, cbError) => ({
  type: referralTypes.GET_REFEREE_PAYMENT,
  payload,
  cb,
  cbError
});

export const recordRefereePaymentAction = (payload, cb, cbError) => ({
  type: referralTypes.RECORD_REFEREE_PAYMENT,
  payload,
  cb,
  cbError
});

export const getPaidTransactionAction = (payload, cb, cbError) => ({
  type: referralTypes.GET_PAID_TRANSACTIONS,
  payload,
  cb,
  cbError
});

export const getSinglePaymentDetailsAction = (payload, cb, cbError) => ({
  type: referralTypes.GET_PAYMENT_DETAILS,
  payload,
  cb,
  cbError
});

export const deleteReferralAction = (payload, cb, cbError) => ({
  type: referralTypes.DELETE_REFERRAL,
  payload,
  cb,
  cbError
});

