import jwtDecode from 'jwt-decode';
import axios from '../services/axios';

export const isAuthenticated = () => {
  const accessToken = localStorage.getItem('accessToken');
  const getResetToken = localStorage.getItem('resetToken');

  if (accessToken || getResetToken) {
    try {
      const token = jwtDecode(accessToken || getResetToken);
      const time = Date.now().valueOf() / 1000;
      if (token.exp < time) {
        return false;
      }
    } catch (error) {
      return false;
    }
    axios.defaults.headers.common.Authorization = accessToken || getResetToken;

    return !!accessToken || getResetToken;
  }
  return false;
};
