const patientTypes = {
  GET_PATIENTS: 'GET_PATIENTS',
  SET_PATIENTS: 'SET_PATIENTS',
  GET_ALL_PATIENTS: 'GET_ALL_PATIENTS',
  SET_ALL_PATIENTS: 'SET_ALL_PATIENTS',
  CREATE_PATIENT: 'CREATE_PATIENT',
  EDIT_PATIENT: 'EDIT_PATIENT',
  SET_PATIENT: 'SET_PATIENT',
  GET_SINGLE_PATIENT: 'GET_SINGLE_PATIENT',
  SET_SINGLE_PATIENT: 'SET_SINGLE_PATIENT',
  GET_PARTNERSHIP_TYPE: 'GET_PARTNERSHIP_TYPE',
  SET_ALL_PARTNERS: 'SET_ALL_PARTNERS',
  GET_BRANCH_PATIENTS: 'GET_BRANCH_PATIENTS',
  SET_BRANCH_PATIENTS: 'SET_BRANCH_PATIENTS',
  VALIDATE_PATIENT_OTP: 'VALIDATE_PATIENT_OTP',
  GET_REFERRAL: 'GET_REFERRAL',
  SET_REFERRAL: 'SET_REFERRAL',
  GET_PROCEDURES: 'GET_PROCEDURES',
  SET_PROCEDURES: 'SET_PROCEDURES',
  SAVE_PROCEDURE_SELECTED: 'SAVE_PROCEDURE_SELECTED',
  GET_APPOINTMENT: 'GET_APPOINTMENT',
  SET_APPOINTMENT: 'SET_APPOINTMENT',
  GET_PACKAGE_DETAILS: 'GET_PACKAGE_DETAILS',
  SET_PACKAGE_DETAILS: 'SET_PACKAGE_DETAILS',
  SAVE_REFERRAL_PATIENT_ID: 'SAVE_REFERRAL_PATIENT_ID',
  GET_SINGLE_BRANCH: 'GET_SINGLE_BRANCH',
  PRICE_TYPES: 'PRICE_TYPES',
  COMPLETE_PATIENT_RECORD: 'COMPLETE_PATIENT_RECORD',
  DELETE_PATIENT: 'DELETE_PATIENT',
  EDIT_PATIENT_VISIT: 'EDIT_PATIENT_VISIT',
  DELETE_PATIENT_VISIT: 'DELETE_PATIENT_VISIT',
  DELETE_PROCEDURE_RECORD: 'DELETE_PROCEDURE_RECORD'
};

export default patientTypes;
