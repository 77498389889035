import React from 'react';
import Modal from 'components/Modal/Modal';
import PropTypes from 'prop-types';
import styles from 'container/profile/profile.module.scss';
import Button from 'components/Button/Button';

const DeleteConfirmationModal = ({ open, close, title, onCancel, onDelete, loading }) => {
  return (
    <Modal isClose={close} isOpen={open} width={464} height={200}>
      <div>
        <h4>Are you sure you want to delete this {title}?</h4>
        <div className={styles.profile__edit__confirm}>
          <Button onClick={onCancel}>No</Button>
          <Button theme="orange" onClick={onDelete} loading={loading} disabled={loading}>
            Yes
          </Button>
        </div>{' '}
      </div>
    </Modal>
  );
};

DeleteConfirmationModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.bool,
  title: PropTypes.string,
  onCancel: PropTypes.bool,
  onDelete: PropTypes.func,
  loading: PropTypes.bool
};

export default DeleteConfirmationModal;
