const roleTypes = {
  GET_ROLES: 'GET_ROLES',
  SET_ROLES: 'SET_ROLES',
  GET_PERMISSIONS: 'GET_PERMISSIONS',
  SET_PERMISSIONS: 'SET_PERMISSIONs',
  CREATE_ROLE: 'CREATE_ROLE',
  SAVE_CREATE_ROLE: 'SAVE_CREATE_ROLE',
  SET_ROLENAME: 'SET_ROLENAME',
  GET_SINGLE_ROLE: 'GET_SINGLE_ROLE',
  SET_SINGLE_ROLE: 'SET_SINGLE_ROLE',
  GET_EDITED_ROLE: 'GET_EDITED_ROLE',
  SET_EDITED_ROLE: 'SET_EDITED_ROLE'
};

export default roleTypes;
