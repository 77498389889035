import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension'; // eslint-disable-line
// storage defaults to localStorage for web and AsyncStorage for react-native
import storage from 'redux-persist/lib/storage';
import { rootReducer, rootSaga } from './re-ducks';
import App from './App';
// import reportWebVitals from './reportWebVitals';

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();
export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);
const persistor = persistStore(store);
// const container = document.getElementById('root');
// const root = createRoot(container);

sagaMiddleware.run(rootSaga);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
