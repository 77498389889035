import clinicianTypes from './clinicians.types';

export const initialState = {
  all_procedures: [],
  visits: [],
  visit_detail: {},
  components: [],
  tests: [],
  test_result: [],
  test_details: {}
};

const clinicianReducer = (state = initialState, action) => {
  switch (action.type) {
    case clinicianTypes.SET_PATIENTS_PROCEDURES:
      return {
        ...state,
        all_procedures: action.payload
      };
    case clinicianTypes.SET_PATIENTS_VISITS:
      return {
        ...state,
        visits: action.payload
      };
    case clinicianTypes.SET_SINGLE_VISIT:
      return {
        ...state,
        visit_detail: action.payload
      };
    case clinicianTypes.SET_TEST_COMPONENT:
      return {
        ...state,
        components: action.payload
      };
    case clinicianTypes.PATIENT_TEST_SELECTED:
      return {
        ...state,
        tests: action.payload
      };
    case clinicianTypes.SET_TEST_RESULT:
      return {
        ...state,
        test_result: action.payload
      };
    case clinicianTypes.SET_TEST_DETAILS:
      return {
        ...state,
        test_details: action.payload
      };
    default:
      return state;
  }
};

export default clinicianReducer;
