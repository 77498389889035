import { API } from 'const';
import queryFormatter from 'helpers/queryFormatter';
import { takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import billingTypes from './billing.types';

export function* getPartnersBillableAmount(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.BILLING}/billings-dashboard?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getPatientsTransactions(action) {
  try {
    const { cb, payload: data } = action;

    const res = yield call(() =>
      api.get(`${API.BILLING}/branch/patients-payment?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getTotalCollectedRevenue(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.BILLING}/branch/total-revenue?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getTotalBookedRevenue(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.BILLING}/branch/total-booked-revenue?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}
function* watchBillingSaga() {
  yield takeEvery(billingTypes.GET_PARTNERS_BILLABLE_AMOUNT, getPartnersBillableAmount);
  yield takeEvery(billingTypes.GET_PATIENTS_TRANSACTIONS, getPatientsTransactions);
  yield takeEvery(billingTypes.GET_TOTAL_COLLECTED_REVENUE, getTotalCollectedRevenue);
  yield takeEvery(billingTypes.GET_TOTAL_BOOKED_REVENUE, getTotalBookedRevenue);
}

export default watchBillingSaga;
