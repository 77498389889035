import partnerTypes from './partners.types';

export const createPartnerAction = (payload, cb, cbError) => ({
  type: partnerTypes.CREATE_PARTNER,
  payload,
  cb,
  cbError
});

export const getPartnerTypeAction = (cb, cbError) => ({
  type: partnerTypes.GET_PARTNERSHIP_TYPE,
  cb,
  cbError
});

export const createPartnersAction = (payload, cb, cbError) => ({
  type: partnerTypes.CREATE_PARTNERS,
  payload,
  cb,
  cbError
});

export const getAllPartnersAction = (payload, cb, cbError) => ({
  type: partnerTypes.GET_ALL_PARTNERS,
  payload,
  cb,
  cbError
});

export const getPartnersPackageAction = (payload, cb, cbError) => ({
  type: partnerTypes.GET_PARTNERS_PACKAGE,
  payload,
  cb,
  cbError
});

export const getPartnerDetailsAction = (payload, cb, cbError) => ({
  type: partnerTypes.GET_PARTNER_DETAILS,
  payload,
  cb,
  cbError
});

export const editPartnerDetailsAction = (payload, cb, cbError) => ({
  type: partnerTypes.EDIT_PARTNER_DETAILS,
  payload,
  cb,
  cbError
});

export const deletePartnerAction = (payload, cb, cbError) => ({
  type: partnerTypes.DELETE_PARTNER,
  payload,
  cb,
  cbError
});

export const updatePartnerStatusAction = (payload, cb, cbError) => ({
  type: partnerTypes.UPDATE_PARTNER_STATUS,
  payload,
  cb,
  cbError
});

export const getBillingOfficerPartnersAction = (payload, cb, cbError) => ({
  type: partnerTypes.GET_BILLING_OFFICER_PARTNER,
  payload,
  cb,
  cbError
});

export const getBillingOfficerPartnerDetails = (payload, cb, cbError) => ({
  type: partnerTypes.GET_BILLING_PARTNER_DETAILS,
  payload,
  cb,
  cbError
});

export const createPartnerPayment = (payload, cb, cbError) => ({
  type: partnerTypes.CREATE_PARTNER_PAYMENT,
  payload,
  cb,
  cbError
});

export const createInvoiceAction = (payload, cb, cbError) => ({
  type: partnerTypes.CREATE_INVOICE,
  payload,
  cb,
  cbError
});

export const getInvoiceDetailsAction = (payload, cb, cbError) => ({
  type: partnerTypes.GET_INVOICE_DETAILS,
  payload,
  cb,
  cbError
});

export const getAllPackageAction = (payload, cb, cbError) => ({
  type: partnerTypes.GET_ALL_PACKAGE,
  payload,
  cb,
  cbError
});

export const getPartnerVisitTransactionsAction = (payload, cb, cbError) => ({
  type: partnerTypes.PARTNER_VISIT_TRANSACTIONS,
  payload,
  cb,
  cbError
});
