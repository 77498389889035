import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from 'router/PrivateRoute';

export const PartnersPage = lazy(() => import('pages/Partners/Partners'));
export const PartnerDetailPage = lazy(() => import('pages/Partners/PartnerDetail'));

export default function PartnerRoutes() {
  return (
    <Routes>
      <Route
        path="/partners"
        element={
          <PrivateRoute>
            <PartnersPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/partners/:id/:page/:type"
        element={
          <PrivateRoute>
            <PartnerDetailPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
