import supportTypes from './support.types';

export const initialState = {
  allTickets: [],
  singleTicket: []
};

const supportReducers = (state = initialState, action) => {
  switch (action.type) {
    case supportTypes.GET_ALL_TICKETS:
      return {
        ...state,
        allTickets: action.payload
      };
      case supportTypes.GET_SINGLE_TICKETS:
        return {
          ...state,
          singleTicket: action.payload
        };
  
    default:
      return state;
  }
};

export default supportReducers;
