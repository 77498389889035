import { API } from 'const';
import queryFormatter from 'helpers/queryFormatter';
import { put, takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import referralTypes from './referral.types';

export function* getAllReferrals(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.FETCH_REFERRALS}?${queryFormatter(data.params)}`));
    if (res.status === 200) {
      yield put({ type: referralTypes.SET_REFERRALS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* doCreateReferral(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`${API.FETCH_REFERRALS}`, data));
    if (res.status === 201) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getSingleReferral(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.FETCH_REFERRALS}/${data?.id}`));
    if (res.status === 200) {
      yield put({ type: referralTypes.SET_SINGLE_REFERRAL, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}
export function* editSingleReferral(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.put(`${API.FETCH_REFERRALS}/${data?.id}`, data.params));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getAllBanks(action) {
  try {
    const { cb } = action;
    const res = yield call(() => api.get(`${API.SETTINGS}/banks`));
    if (res.status === 200) {
      yield put({ type: referralTypes.SET_BANKS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getRefereeInstitution(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.REFEREE_INSTITUTION}/${data.id}`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* referralDuePayment(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() =>
      api.get(`${API.FETCH_REFERRALS}/${data.id}/transactions?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: referralTypes.SET_DUE_PAYMENT, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* refereePayment(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() =>
      api.get(`${API.FETCH_REFERRALS}/${data.id}/${data.payment_id}/transaction`)
    );
    if (res.status === 200) {
      yield put({ type: referralTypes.SET_REFEREE_PAYMENT, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* recordRefereePayment(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.post(`${API.FETCH_REFERRALS}/pay-referee/${data.id}`, data.params)
    );
    if (res.status === 201) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* referralPaidTransactions(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() =>
      api.get(`${API.FETCH_REFERRALS}/${data.id}/paid-transactions?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: referralTypes.SET_PAID_TRANSACTIONS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getSinglePaymentDetails(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.get(`${API.FETCH_REFERRALS}/${data.id}/transaction-details`));
    if (res.status === 200) {
      yield put({ type: referralTypes.SET_PAYMENT_DETAILS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* deleteReferral(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.delete(`${API.FETCH_REFERRALS}/delete/${data?.id}?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* watchReferralSaga() {
  yield takeEvery(referralTypes.FETCH_REFERRALS, getAllReferrals);
  yield takeEvery(referralTypes.CREATE_REFERRAL, doCreateReferral);
  yield takeEvery(referralTypes.FETCH_SINGLE_REFERRAL, getSingleReferral);
  yield takeEvery(referralTypes.EDIT_REFERRAL, editSingleReferral);
  yield takeEvery(referralTypes.FETCH_BANKS, getAllBanks);
  yield takeEvery(referralTypes.GET_REFEREE_INSTITUTION, getRefereeInstitution);
  yield takeEvery(referralTypes.GET_DUE_PAYMENT, referralDuePayment);
  yield takeEvery(referralTypes.GET_REFEREE_PAYMENT, refereePayment);
  yield takeEvery(referralTypes.RECORD_REFEREE_PAYMENT, recordRefereePayment);
  yield takeEvery(referralTypes.GET_PAID_TRANSACTIONS, referralPaidTransactions);
  yield takeEvery(referralTypes.GET_PAYMENT_DETAILS, getSinglePaymentDetails);
  yield takeEvery(referralTypes.DELETE_REFERRAL, deleteReferral);
}

export default watchReferralSaga;
