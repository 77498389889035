import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from 'router/PrivateRoute';

export const SupportPage = lazy(() => import('pages/Support'));
export const CreateNewTicketPage = lazy(() => import('container/Support/CreateTicket'));
export const ReplySupportPage = lazy(() => import('container/Support/ReplyTicket'));

export default function SupportRoutes() {
  return (
    <Routes>
      <Route
        path="/support"
        element={
          <PrivateRoute>
            <SupportPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/support/create-new-ticket"
        element={
          <PrivateRoute>
            <CreateNewTicketPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/support/reply-ticket/:branch/:id/:page"
        element={
          <PrivateRoute>
            <ReplySupportPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
