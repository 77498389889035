import roleTypes from './roles.types';

export const initialState = {
  role: [],
  permissions: {},
  roleName: {},
  singleRole: {}
};

const roleReducers = (state = initialState, action) => {
  switch (action.type) {
    case roleTypes.SET_ROLES:
      return {
        ...state,
        role: action.payload
      };
    case roleTypes.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload
      };
    case roleTypes.SET_ROLENAME:
      return {
        ...state,
        roleName: action.payload
      };
    case roleTypes.SET_SINGLE_ROLE:
      return {
        ...state,
        singleRole: action.payload
      };
    default:
      return state;
  }
};

export default roleReducers;
