import { API } from 'const';
import { takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import queryFormatter from 'helpers/queryFormatter';
import partnerTypes from './partners.types';

function* getPartnerType(action) {
  try {
    const { cb } = action;

    const res = yield call(() => api.get(`${API.PARTNERSHIP_TYPE}`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getAllPartners(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.get(`${API.PARTNERS}?${queryFormatter(data.params)}`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getPartnersPackage(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.get(`${API.PACKAGE}/${data.id}/partner-packages`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getBillingOfficerPartners(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() =>
      api.get(`${API.PARTNERS}/billing-officer?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getSIngleBillingOfficerPartner(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() =>
      api.get(`${API.PARTNERS}/${data.id}/patients?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getPartnerDetails(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.get(`${API.PARTNERS}/${data.id}/details`, data?.data));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* editPartnerDetails(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.put(`${API.PARTNERS}/${data.id}`, data?.data));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* deletePartner(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.delete(`${API.PARTNERS}/${data}`));
    if (res.status === 200) {
      cb();
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* updatePartnerStatus(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.patch(`${API.PARTNERS}/${data.id}/status`, data?.status));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* createPartnerPayment(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.post(`${API.PARTNERS}/payments`, data.payment));
    if (res.status === 201 || res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* createInvoice(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.post(`${API.SETTINGS}/invoice`, data));
    if (res.status === 201) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getInvoiceDetails(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.get(`${API.SETTINGS}/invoice/${data.params}`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getPackages(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.get(`${API.PACKAGE}?${queryFormatter(data.params)}`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getPartnerVisitTransactions(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.get(`${API.PARTNERS}/transactions?${queryFormatter(data.params)}`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* watchPartnerSaga() {
  yield takeEvery(partnerTypes.GET_PARTNERSHIP_TYPE, getPartnerType);
  yield takeEvery(partnerTypes.GET_ALL_PARTNERS, getAllPartners);
  yield takeEvery(partnerTypes.GET_BILLING_OFFICER_PARTNER, getBillingOfficerPartners);
  yield takeEvery(partnerTypes.GET_BILLING_PARTNER_DETAILS, getSIngleBillingOfficerPartner);
  yield takeEvery(partnerTypes.GET_PARTNER_DETAILS, getPartnerDetails);
  yield takeEvery(partnerTypes.EDIT_PARTNER_DETAILS, editPartnerDetails);
  yield takeEvery(partnerTypes.DELETE_PARTNER, deletePartner);
  yield takeEvery(partnerTypes.UPDATE_PARTNER_STATUS, updatePartnerStatus);
  yield takeEvery(partnerTypes.CREATE_PARTNER_PAYMENT, createPartnerPayment);
  yield takeEvery(partnerTypes.GET_PARTNERS_PACKAGE, getPartnersPackage);
  yield takeEvery(partnerTypes.CREATE_INVOICE, createInvoice);
  yield takeEvery(partnerTypes.GET_INVOICE_DETAILS, getInvoiceDetails);
  yield takeEvery(partnerTypes.GET_ALL_PACKAGE, getPackages);
  yield takeEvery(partnerTypes.PARTNER_VISIT_TRANSACTIONS, getPartnerVisitTransactions);
}

export default watchPartnerSaga;
