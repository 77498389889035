import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';
import BackArrow from 'assets/icons/back.svg';
import Close from 'assets/icons/close.svg';
import styles from './layouts.module.scss';

const FormLayout = ({
  children,
  showTitle,
  showCaption,
  title,
  caption,
  closeLink,
  patientCheck,
  closeLink2
}) => {
  const navigate = useNavigate();
  const handleClose = () => {
    return patientCheck ? closeLink2 : navigate(-1);
  };

  return (
    <div className={styles.formlayout__container}>
      <div className={styles.formlayout__content}>
        <div className={styles.formlayout__button}>
          <Button
            theme="tertiary"
            dataTestId="back"
            onClick={patientCheck ? closeLink : () => navigate(-1)}>
            <img src={BackArrow} alt="back" /> &nbsp; Back
          </Button>
          <Button theme="tertiary" dataTestId="close" onClick={handleClose}>
            Close &nbsp; <img src={Close} alt="back" theme="tertiary" />
          </Button>
        </div>
        <div className={styles.formlayout__form2}>
          {showTitle && <h2 className={styles.formlayout__title}>{title}</h2>}
          {showCaption && <p className={styles.formlayout__description}>{caption}</p>}
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

FormLayout.propTypes = {
  children: PropTypes.node,
  showTitle: PropTypes.bool,
  showCaption: PropTypes.bool,
  title: PropTypes.string,
  caption: PropTypes.string,
  addButton: PropTypes.string,
  add: PropTypes.bool,
  closeLink: PropTypes.string,
  patientCheck: PropTypes.bool,
  closeLink2: PropTypes.string,
};

export default FormLayout;
