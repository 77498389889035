import { action } from "@storybook/addon-actions";
import invoicesTypes from "./invoices.types";

export const initialState = {
  invoices: [],
};
const invoicesReducer = (state = initialState,action) => {
    switch (action.type){
        case invoicesTypes.GET_ALL_INVOICES:
            return {
                ...state,
                invoices: action.payload
            };
            default: 
            return state;
    }
};

export default invoicesReducer;
