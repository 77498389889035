import referralTypes from './referral.types';

export const initialState = {
  referrals: [],
  single_referral: {},
  banks: [],
  due_payment: [],
  referee_payment: [],
  paid_transactions: [],
  payment_details: {}
};

const referralReducer = (state = initialState, action) => {
  switch (action.type) {
    case referralTypes.SET_REFERRALS:
      return {
        ...state,
        referrals: action.payload
      };
    case referralTypes.SET_SINGLE_REFERRAL:
      return {
        ...state,
        single_referral: action.payload
      };
    case referralTypes.SET_BANKS:
      return {
        ...state,
        banks: action.payload
      };
    case referralTypes.SET_DUE_PAYMENT:
      return {
        ...state,
        due_payment: action.payload
      };
    case referralTypes.SET_REFEREE_PAYMENT:
      return {
        ...state,
        referee_payment: action.payload
      };
    case referralTypes.SET_PAID_TRANSACTIONS:
      return {
        ...state,
        paid_transactions: action.payload
      };
    case referralTypes.SET_PAYMENT_DETAILS:
      return {
        ...state,
        payment_details: action.payload
      };
    default:
      return state;
  }
};

export default referralReducer;
