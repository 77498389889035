import profileTypes from './profile.types';

export const getProfileAction = (cb, cbError) => ({
  type: profileTypes.GET_PROFILE,
  cb,
  cbError
});

export const editProfileAction = (payload, cb, cbError) => ({
  type: profileTypes.EDIT_PROFILE,
  payload,
  cb,
  cbError
});

export const getBranchesAction = (cb, cbError) => ({
  type: profileTypes.GET_CENTERS,
  cb,
  cbError
});
