const auditTypes = {
  GET_ALL_AUDITS: 'GET_ALL_AUDITS',
  SET_ALL_AUDITS: 'SET_ALL_AUDITS',
  GET_AUDIT_DETAILS: 'GET_AUDIT_DETAILS',
  SET_AUDIT_DETAILS: 'SET_AUDIT_DETAILS',
  GET_AUDIT_LOGS: 'GET_AUDIT_LOGS',
  SET_AUDIT_LOGS: 'SET_AUDIT_LOGS'
};

export default auditTypes;
