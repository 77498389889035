const paymentTypes = {
  GET_PAYMENT_RECORD: 'GET_PAYMENT_RECORD',
  GET_PAYMENT_TRACKER: 'GET_PAYMENT_TRACKER',
  PAYMENT_DETAILS: 'PAYMENT_DETAILS',
  SAVE_PAYMENT_DETAILS: 'SAVE_PAYMENT_DETAILS',
  PAYMENT_TRACKER: 'PAYMENT_TRACKER',
  UPDATE_PAYMENT: 'UPDATE_PAYMENT',
  RECORD_PAYMENT: 'RECORD_PAYMENT',
  APPROVE_BONUS_REQUEST: 'APPROVE_BONUS_REQUEST',
  SEND_PARTNER_BILL: 'SEND_PARTNER_BILL',
  SEND_RECORDS_TO_CLINICAN: 'SEND_RECORDS_TO_CLINICAN',
  DISCOUNT_APPROVAL: 'DISCOUNT_APPROVAL',
  GET_TRANSACTION_TAB: 'GET_TRANSACTION_TAB',
  CREATE_RECEIPT: 'CREATE_RECEIPT',
  GET_SINGLE_RECEIPT: ' GET_SINGLE_RECEIPT',
  GET_PARTNER_PATIENT_RECEIPT_DATA: 'GET_PARTNER_PATIENT_RECEIPT_DATA',
  GET_PATIENT_RECEIPT_DATA: 'GET_PATIENT_RECEIPT_DATA'
};

export default paymentTypes;
