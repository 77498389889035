const actionTypes = {
  DO_SIGNIN: 'DO_SIGNIN',
  SET_SIGNIN: 'SET_SIGNIN',
  DO_FORGET_PASSWORD: 'DO_FORGET_PASSWORD',
  SET_FORGET_PASSWORD: 'SET_FORGET_PASSWORD',
  DO_RESET_PASSWORD: 'DO_RESET_PASSWORD',
  SET_RESET_PASSWORD: 'SET_RESET_PASSWORD',
  DO_SET_PASSWORD: 'DO_SET_PASSWORD',
  SET_SET_PASSWORD: 'SET_SET_PASSWORD',
  DO_CHANGE_PASSWORD: 'DO_CHANGE_PASSWORD',
  VALIDATE_OTP: 'VALIDATE_OTP',
  SET_VALIDATE_OTP: 'SET_VALIDATE_OTP',
  RESEND_CODE: 'RESEND_CODE',
  SET_PASSWORD: 'SET_PASSWORD',
  SIGNOUT: 'SIGNOUT',
  DO_REFRESH_TOKEN: 'DO_REFRESH_TOKEN',
  SET_REFRESH_TOKEN: 'SET_REFRESH_TOKEN'
};

export default actionTypes;
