import { API } from "const";
import queryFormatter from "helpers/queryFormatter";
import { call, takeEvery } from "redux-saga/effects";
import api from "services/api";
import invoicesTypes from "./invoices.types";


export function* getAllInvoices(action){
    try{
        const{payload:data,cb}= action;
        const res = yield call(()=>api.get(`${API.INVOICES}?${queryFormatter(data.params)}`));
        if (res.status === 200) {
            cb(res.data.data);
          }
        } catch (err) {
          const { cbError } = action;
          const alert = err?.response?.data?.message || '';
          cbError(alert);
        }
    }

    function* watchInvoicesSaga() {
        yield takeEvery(invoicesTypes.GET_ALL_INVOICES, getAllInvoices)
    }
    export default watchInvoicesSaga;