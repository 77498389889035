import React from 'react';
import FormLayout from '../Layouts/formlayout';
import EditVisitRefferalSourceForm from 'container/Forms/centerForm/EditVisitReferralSourceForm';

const EditVisitRefferalSource = () => {
  return (
    <div>
      <FormLayout
        title="Edit Patient Visit"
        caption="Select Patient Visit information."
        showTitle
        showCaption>
        <EditVisitRefferalSourceForm />
      </FormLayout>
    </div>
  );
};

export default EditVisitRefferalSource;
