import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from 'router/PrivateRoute';

export const LogNewEntryPage = lazy(() => import('pages/FrontDesk/LogNewEntry'));
export const LogNewEntryOTPPage = lazy(() => import('pages/FrontDesk/LogNewEntryOTP'));

export default function LogNewEntryRoutes() {
  return (
    <Routes>
      <Route
        path="/log-new-entry"
        element={
          <PrivateRoute>
            <LogNewEntryPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/log-new-entry/otp"
        element={
          <PrivateRoute>
            <LogNewEntryOTPPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
