import React from 'react';
import PropTypes from 'prop-types';
import styles from './checkbox.module.scss';

const Checkbox = ({ name, onClick, checked, dataTestId, onChange, id }) => (
  <label className={styles.container}>
    <input
      id={id}
      type="checkbox"
      name={name}
      data-testId={dataTestId}
      onClick={onClick}
      onChange={onChange}
      checked={checked}
    />
    <span className={styles.checkmark} />
  </label>
);

Checkbox.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  checked: PropTypes.any,
  dataTestId: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string
};

export default Checkbox;
