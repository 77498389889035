import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

export const VisitsPage = lazy(() => import('pages/Visits'));
export const VisitDetailsPage = lazy(() => import('container/Visits/visitDetails'));

export default function VisitsRoutes() {
  return (
    <Routes>
      <Route path="/visits" element={<VisitsPage />} />
      <Route path="/visits/details/:id/:page" element={<VisitDetailsPage />} />
    </Routes>
  );
}
