import actionTypes from './auth.types';

export const doSignInAction = (payload, cb, cbError) => ({
  type: actionTypes.DO_SIGNIN,
  payload,
  cb,
  cbError
});

export const doForgetPasswordAction = (payload, cb, cbError) => ({
  type: actionTypes.DO_FORGET_PASSWORD,
  payload,
  cb,
  cbError
});

export const doValidateOTPAction = (payload, cb, cbError) => ({
  type: actionTypes.VALIDATE_OTP,
  payload,
  cb,
  cbError
});

export const doReSetPasswordAction = (payload, cb, cbError) => ({
  type: actionTypes.DO_RESET_PASSWORD,
  payload,
  cb,
  cbError
});

export const doResendCodeAction = (payload, cb, cbError) => ({
  type: actionTypes.RESEND_CODE,
  payload,
  cb,
  cbError
});

export const doSetPasswordAction = (payload, cb, cbError) => ({
  type: actionTypes.DO_SET_PASSWORD,
  payload,
  cb,
  cbError
});

export const doChangePasswordAction = (payload, cb, cbError) => ({
  type: actionTypes.DO_CHANGE_PASSWORD,
  payload,
  cb,
  cbError
});

export const doRefreshTokenAction = (payload, cb, cbError) => ({
  type: actionTypes.DO_REFRESH_TOKEN,
  payload,
  cb,
  cbError
});
