import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from 'router/PrivateRoute';

export const PatientPage = lazy(() => import('pages/Patient/Patient'));
export const PatientDetailsPage = lazy(() => import('container/Patient/PatientDetails'));
export const QueuePatientDetailsPage = lazy(() => import('pages/FrontDesk/QueuePatientDetails'));
export const CreatePatientPage = lazy(() => import('container/Patient/CreatePatient'));
export const EditPatientPage = lazy(() => import('container/Patient/EditPatient'));
export const PatientHistoryPage = lazy(() => import('pages/PatientHistory/PatientHistory'));
export const ViewMorePage = lazy(() => import('pages/Patient/ViewMore'));
export const HistoryDetailsPage = lazy(() => import('container/PatientHistory/historyDetails'));

export default function PatientRoutes() {
  return (
    <Routes>
      <Route
        path="/patient"
        element={
          <PrivateRoute>
            <PatientPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/patient/:id"
        element={
          <PrivateRoute>
            <PatientDetailsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/front-desk/patient-details/:id/:page"
        element={
          <PrivateRoute>
            <QueuePatientDetailsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/create-patient"
        element={
          <PrivateRoute>
            <CreatePatientPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/edit-patient/:id"
        element={
          <PrivateRoute>
            <EditPatientPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/patient/:id/view-more"
        element={
          <PrivateRoute>
            <ViewMorePage />
          </PrivateRoute>
        }
      />
      <Route path="/visit/patient-history" element={<PatientHistoryPage />} />
      <Route path="/visit/patient-history/details/:id/:page" element={<HistoryDetailsPage />} />
    </Routes>
  );
}
