import { API } from 'const';
import queryFormatter from 'helpers/queryFormatter';
import { put, takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import rebateTypes from './rebate.types';

export function* getRebateSchedules(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.FETCH_REFERRALS}/rebate/schedule?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: rebateTypes.SET_REBATE_SCHEDULES, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getRebateSchedulesDetails(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(
        data?.data
          ? `${API.FETCH_REFERRALS}/schedule/${data.id}?${queryFormatter(data.params)}&${
              data?.data
            }`
          : `${API.FETCH_REFERRALS}/schedule/${data.id}?${queryFormatter(data.params)}`
      )
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getResponderRebates(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.FETCH_REFERRALS}/rebate/schedule/${data.id}?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* markRebateAsPaid(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`${API.FETCH_REFERRALS}/pay-referee`, data));
    if (res.status === 201) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* watchRebateSaga() {
  yield takeEvery(rebateTypes.GET_REBATE_SCHEDULES, getRebateSchedules);
  yield takeEvery(rebateTypes.GET_REBATE_SCHEDULE_DETAILS, getRebateSchedulesDetails);
  yield takeEvery(rebateTypes.GET_RESPONDER_REBATES, getResponderRebates);
  yield takeEvery(rebateTypes.MARK_REBATE_AS_PAID, markRebateAsPaid);
}

export default watchRebateSaga;
