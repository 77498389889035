import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

export const WaitListPage = lazy(() => import('pages/WaitList/WaitList'));
export const TestResultPage = lazy(() => import('pages/TestResult/TestResult'));
export const TestDetailsPage = lazy(() => import('container/TestResult/details'));
export const TemplatePage = lazy(() => import('container/Template'));

export default function WaitlistRoutes() {
  return (
    <Routes>
      <Route path="/wait-list" element={<WaitListPage />} />
      <Route path="/result" element={<TestResultPage />} />
      <Route
        path="/result/details/:id/:visit_id/resident/:page/:type"
        element={<TestDetailsPage />}
      />
      <Route
        path="/result/details/:id/:visit_id/incoming/:page/:type"
        element={<TestDetailsPage />}
      />
      <Route path="/result/template/:id/:visit_id/:page/:type" element={<TemplatePage />} />
      <Route
        path="/result/template/:id/incoming/:visit_id/:page/:type"
        element={<TemplatePage />}
      />
      <Route path="/result/template/:id/:visit_id/edit/:page/:type" element={<TemplatePage />} />
      <Route
        path="/result/template/:id/incoming/:visit_id/edit/:page/:type"
        element={<TemplatePage />}
      />
    </Routes>
  );
}
