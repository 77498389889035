import visitTypes from './visit.types';

export const fetchAllVisitRecordsAction = (payload, cb, cbError) => ({
  type: visitTypes.FETCH_VISIT_RECORDS,
  payload,
  cb,
  cbError
});

export const fetchAllVisitDetailsAction = (payload, cb, cbError) => ({
  type: visitTypes.FETCH_VISIT_DETAILS,
  payload,
  cb,
  cbError
});

export const updateResultStatusActions = (payload, cb, cbError) => ({
  type: visitTypes.UPDATE_RESULT_STATUS,
  payload,
  cb,
  cbError
});
