import billingTypes from './billing.types';

export const getPartnersBillableAmountAction = (payload, cb, cbError) => ({
  type: billingTypes.GET_PARTNERS_BILLABLE_AMOUNT,
  payload,
  cb,
  cbError
});

export const getPatientsTransactionsAction = (payload, cb, cbError) => ({
  type: billingTypes.GET_PATIENTS_TRANSACTIONS,
  payload,
  cb,
  cbError
});

export const getTotalBookedRevenueAction = (payload, cb, cbError) => ({
  type: billingTypes.GET_TOTAL_BOOKED_REVENUE,
  payload,
  cb,
  cbError
});

export const getTotalCollectedRevenueAction = (payload, cb, cbError) => ({
  type: billingTypes.GET_TOTAL_COLLECTED_REVENUE,
  payload,
  cb,
  cbError
});
