import { API } from 'const';
import queryFormatter from 'helpers/queryFormatter';
import { put, takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import reportTypes from './report.types';

export function* saveReportToDraft(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.put(`${API.PATIENT}/records/clinician`, data.params));
    if (res.status === 200) {
      cb(res);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getPatientResults(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.PATIENT}/records/clinician?ids=${data.ids}`));
    if (res.status === 200) {
      yield put({ type: reportTypes.SET_PATIENT_RESULT, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getAnotherBranchPatientRecord(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.PATIENT}/${data.id}/records?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: reportTypes.SET_INCOMING_PATIENT_RECORD, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* watchReportSaga() {
  yield takeEvery(reportTypes.SAVE_REPORT_TO_DRAFT, saveReportToDraft);
  yield takeEvery(reportTypes.GET_PATIENT_RESULT, getPatientResults);
  yield takeEvery(reportTypes.GET_INCOMING_PATIENT_RECORD, getAnotherBranchPatientRecord);
}

export default watchReportSaga;
