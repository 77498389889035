const supportTypes = {
  GET_ALL_TICKETS: 'GET_ALL_TICKETS',
  GET_TICKETS_BY_STATUS: 'GET_TICKETS_BY_STATUS',
  GET_SINGLE_TICKETS: 'GET_SINGLE_TICKETS',
  CREATE_TICKET: 'CREATE_TICKET',
  CREATE_MESSAGE: 'CREATE_MESSAGE',
  UPDATE_TICKET_STATUS: 'UPDATE_TICKET_STATUS'
};

export default supportTypes;
