import { takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import queryFormatter from 'helpers/queryFormatter';
import notificationTypes from './notification.types';

export function* getNotificationStatusUpdate(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`user/firebase-update?${queryFormatter(data.params)}`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* watchNotificationSaga() {
  yield takeEvery(notificationTypes.UPDATE_NOTIFICATION_STATUS, getNotificationStatusUpdate);
}

export default watchNotificationSaga;
