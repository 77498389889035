import React from 'react';
import styles from './table.module.scss';
import PropTypes from 'prop-types';
import placeholder from 'assets/icons/table-placeholder.svg';

const TableBody = ({
  tableData,
  onClick,
  content,
  cols,
  placeHolderImg,
  hideEmptyDetails,
  placeholderText,
  emptyState,
  placeHolderCaption,
  onMouseEnter,
  onMouseLeave
}) => (
  <tbody className="table__body">
    {!tableData?.length ||
      (hideEmptyDetails && (
        <tr className={styles.table__row}>
          <td className="" colSpan={cols}>
            <div className={styles.table__empty}>
              <div>
                {placeHolderImg || <img src={placeholder} alt="" />}{' '}
                <p>No {placeholderText} found</p>
              </div>
            </div>
          </td>
        </tr>
      ))}
    {!tableData?.length && emptyState && (
      <tr className={styles.table__row}>
        <td className="" colSpan={cols}>
          <div className={styles.table__empty}>
            <div>
              <img src={placeholder} alt="" />
              {placeholderText ? <p>No {placeholderText} found</p> : <p>{placeHolderCaption}</p>}
            </div>
          </div>
        </td>
      </tr>
    )}
    {tableData?.map((row, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <tr
        data-testid="table-row"
        className={styles.table__row}
        key={index}
        onMouseEnter={() => {
          if (!onMouseEnter) return;
          onMouseEnter(row);
        }}
        onMouseLeave={onMouseLeave}
        onClick={() => {
          if (!onClick) return;
          onClick(row);
        }}>
        <>{content(row, index)}</>
      </tr>
    ))}
  </tbody>
);
TableBody.defaultProps = {
  placeHolderImg: null
};

TableBody.propTypes = {
  placeHolderImg: PropTypes.node,
  tableData: PropTypes.arrayOf(PropTypes.any),
  content: PropTypes.func,
  cols: PropTypes.number,
  hideEmptyDetails: PropTypes.bool,
  onClick: PropTypes.func,
  placeholderText: PropTypes.string,
  emptyState: PropTypes.bool,
  placeHolderCaption: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
};

export default TableBody;
