import rebateTypes from './rebate.types';

export const initialState = {
  rebates: []
};

const rebateReducers = (state = initialState, action) => {
  switch (action.type) {
    case rebateTypes.SET_REBATE_SCHEDULES:
      return {
        ...state,
        rebates: action.payload
      };
    default:
      return state;
  }
};

export default rebateReducers;
