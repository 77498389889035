import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from 'router/PrivateRoute';

export const ProfilePage = lazy(() => import('pages/Profile/Profile'));
export const ClinicalPage = lazy(() => import('pages/Clinical/Clinical'));
export const AdminPage = lazy(() => import('pages/Admin/Admin'));
export const RoleManagementPage = lazy(() => import('pages/RoleManagement/RoleManagement'));
export const CreateRolePage = lazy(() => import('container/RoleManagement/createRole'));
export const EditRoleAndPermissionPage = lazy(() =>
  import('container/RoleManagement/editRoleAndPermission')
);
export const ViewPermissionPage = lazy(() => import('container/RoleManagement/viewPermissions'));

export default function RolesRoutes() {
  return (
    <Routes>
      <Route
        path="/profile-settings"
        element={
          <PrivateRoute>
            <ProfilePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/clinical"
        element={
          <PrivateRoute>
            <ClinicalPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin-management"
        element={
          <PrivateRoute>
            <AdminPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/role-management"
        element={
          <PrivateRoute>
            <RoleManagementPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/create-role"
        element={
          <PrivateRoute>
            <CreateRolePage />
          </PrivateRoute>
        }
      />

      <Route
        path="/view-permission/:id/:page"
        element={
          <PrivateRoute>
            <ViewPermissionPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/edit-role-permission/:id/:page"
        element={
          <PrivateRoute>
            <EditRoleAndPermissionPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
