import profileTypes from './profile.types';

export const initialState = {
  profile: {},
  centers: []
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case profileTypes.SET_PROFILE:
      return {
        ...state,
        profile: action.payload
      };
    case profileTypes.SET_CENTERS:
      return {
        ...state,
        centers: action.payload
      };
    default:
      return state;
  }
};

export default profileReducer;
