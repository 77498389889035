import { API } from 'const';
import { put, takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import queryFormatter from 'helpers/queryFormatter';
import visitTypes from './visit.types';

export function* fetchAllVisitRecords(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.PATIENT}/visits-records?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: visitTypes.SET_VISIT_RECORDS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* fetchVisitDetails(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.PATIENT}/visits-records/${data.id}`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* updateResultStatus(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.put(`${API.PATIENT}/results-tracking`, data));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export default function* watchVisitSaga() {
  yield takeEvery(visitTypes.FETCH_VISIT_RECORDS, fetchAllVisitRecords);
  yield takeEvery(visitTypes.FETCH_VISIT_DETAILS, fetchVisitDetails);
  yield takeEvery(visitTypes?.UPDATE_RESULT_STATUS, updateResultStatus);
}
