const { useState, useEffect } = require('react');
import {
  getAllPackageAction,
  getAllPartnersAction,
  getPartnersPackageAction,
  getPartnerTypeAction
} from 're-ducks/partner';
import { getReferralAction, getRefereeInstitutions } from 're-ducks/referral';
import { useDispatch, useSelector } from 'react-redux';

const useEditNewVisit = () => {
  const { payment_details } = useSelector((state) => state?.payment);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth?.signIn);
  const [referringIndividual, setReferringIndividual] = useState([]);
  const [selectReferringIndividual, setSelectedReferringIndividual] = useState({
    label: payment_details?.institution_name === null ? 'Self' : payment_details?.institution_name,
    value: payment_details?.referee_id === null ? undefined : payment_details?.referee_id
  });
  const [referringInstitution, setReferringInstituion] = useState([]);
  const [selectReferringInstitution, setSelectReferringInstitution] = useState({
    label: payment_details?.referring_institution,
    value: payment_details?.referring_institution_id
  });
  const [selectPayingSource, setSelectPayingSource] = useState([]);
  const [payingSource, setPayingSource] = useState({
    label: payment_details?.paying_source,
    value: payment_details?.paying_source,
    code: ''
  });
  const [institutionName, setInstitutionName] = useState([]);
  const [selectInstitutionName, setSelectInstitutionName] = useState({
    label: payment_details?.paying_institution,
    value: payment_details?.paying_institution_id
  });
  const [packages, setPackages] = useState([]);
  const [selectPackage, setSelectPackage] = useState({
    label: payment_details?.package_name,
    value: payment_details?.package_id
  });
  const [selectAuthorizationCode, setSelectAuthorizationCode] = useState(
    payment_details?.authorization_code
  );
  const [selectEnrolleeID, setSelectEnrolleeID] = useState(payment_details?.enrollee_id);
  const [visitNote, setVisitNote] = useState(payment_details?.visit_notes);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getReferralAction(
        {
          params: {
            center_id: auth.branch_id
          }
        },
        (res) => {
          setReferringIndividual([
            { full_name: 'Self' },
            ...res.map((item) => ({
              full_name: item?.full_name,
              id: item?.id
            }))
          ]);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      )
    );
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getPartnerTypeAction(
        (res) => {
          setSelectPayingSource([
            ...res.partnerships.map((item) => ({
              name: item?.name,
              code: item?.code
            })),
            { name: 'out of pocket', code: 'oop' }
          ]);
        },
        () => {}
      )
    );
  }, []);

  useEffect(() => {
    setLoading(true);
    const code = localStorage.getItem('code');
    dispatch(
      getAllPartnersAction(
        {
          params: {
            type: code
          }
        },
        () => {
          setLoading(false);
          localStorage.removeItem('code');
        },
        () => {
          setLoading(false);
        }
      )
    );
  }, []);

  useEffect(() => {
    if (payingSource?.value === 'out of pocket') {
      dispatch(
        getAllPackageAction(
          { params: { type: 'Self Pay' } },
          (res) => {
            if (payingSource?.value === 'out of pocket') {
              setPackages(res?.packages);
            } else {
              setPackages(res);
            }
            setLoading(false);
          },
          () => {
            setLoading(false);
          }
        )
      );
    }
  }, []);
  const handleReferralIndividualChange = (e) => {
    setSelectedReferringIndividual(e);
    if (e?.value === undefined) {
      setSelectReferringInstitution({ label: '', value: null });
    }
    if (e?.value.length > 0) {
      setSelectReferringInstitution({ label: '', value: null });
    }
    dispatch(
      getRefereeInstitutions(
        { id: e.value },
        (res) => {
          setReferringInstituion(res);
        },
        () => {}
      )
    );
  };
  const handleReferringInstitutionChange = (e) => {
    setSelectReferringInstitution(e);
  };
  const handlePayingSourceChange = (e) => {
    setPayingSource(e);
    if (e?.value === 'HEALTH-FACILITIES') {
      handleInstitutionChange(selectReferringInstitution);
      setSelectPackage({ label: '', value: null });
      setSelectAuthorizationCode(null);
      setSelectEnrolleeID(null);
    }
    if (e.value === 'out of pocket' || e?.value === 'BUSINESS') {
      setSelectInstitutionName({ label: '', value: null });
      setSelectPackage({ label: '', value: null });
      setSelectAuthorizationCode(null);
      setSelectEnrolleeID(null);
    }
    if (e?.value === 'HMO') {
      setSelectInstitutionName({ label: '', value: null });
      setSelectPackage({ label: '', value: null });
      setSelectAuthorizationCode('');
      setSelectEnrolleeID('');
    }
    if (e?.value === 'out of pocket') {
      dispatch(
        getAllPackageAction(
          { params: { type: 'Self Pay' } },
          (res) => {
            if (e?.value === 'out of pocket') {
              setPackages(res?.packages);
            } else {
              setPackages(res);
            }
            setLoading(false);
          },
          () => {
            setLoading(false);
          }
        )
      );
    }
    dispatch(
      getAllPartnersAction(
        {
          params: {
            type: e.code
          }
        },
        (res) => {
          localStorage.setItem('code', e?.code);
          setInstitutionName(res?.partners?.map((i) => ({ name: i?.name, code: i?.id })));
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      )
    );
  };

  const handleInstitutionChange = (e) => {
    setSelectInstitutionName(e);
    if (e?.value) {
      setSelectPackage({ label: '', value: null });
    }
    dispatch(
      getPartnersPackageAction(
        { id: e?.value },
        (res) => {
          setPackages(res);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      )
    );
  };
  const handleSelectedPackage = (e) => {
    setSelectPackage(e);
  };
  const handleAuthorizationCode = (e) => {
    setSelectAuthorizationCode(e.target.value);
  };
  const handleEnrolleeID = (e) => {
    setSelectEnrolleeID(e.target.value);
  };

  return {
    referringIndividual,
    selectReferringIndividual,
    referringInstitution,
    selectReferringInstitution,
    selectPayingSource,
    institutionName,
    payingSource,
    selectInstitutionName,
    packages,
    selectPackage,
    selectAuthorizationCode,
    selectEnrolleeID,
    visitNote,
    loading,
    handleReferralIndividualChange,
    handleReferringInstitutionChange,
    handlePayingSourceChange,
    handleInstitutionChange,
    handleSelectedPackage,
    handleAuthorizationCode,
    handleEnrolleeID,
    setVisitNote,
    setLoading
  };
};

export default useEditNewVisit;
