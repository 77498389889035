import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import styles from '../form.module.scss';
import Button from '../../../components/Button/Button';
import Dots from '../../../assets/icons/vertical-dot.svg';
import FormInput from '../../../components/FormInput/FormInput';
import { Formik, Form } from 'formik';
import ReactSelect from '../../../components/Select/ReactSelect';
import Table from 'components/Table';
import Indicator from 'components/indicator';
import priceSplitter from 'utils/priceSplitter';
import { singleQueueHeaders } from 'mocks/frontdesk';
import { FormatDate } from 'helpers/formatDate';
import useEditNewVisit from 'hooks/useEditNewVisit';
import { deleteProcedureRecordAction, editPatientVisitAction } from 're-ducks/patients';
import { toast } from 'react-toastify';
import Dropdown from 'components/Dropdown/Dropdown';
import { Option } from 'components/Dropdown/Option';
import DeleteConfirmationModal from 'container/Modal/DeleteModal';
import { getPaymentDetailsAction } from 're-ducks/payment';
import ChooseProcedureModal from 'container/FrontDesk/ChooseProcedureModal';
import PackagesModal from 'container/FrontDesk/PackagesModal';

const EditVisitRefferalSourceForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [openProcedureModal, setProcedureModal] = useState(false);
  const [openPackageModal, setPackageModal] = useState(false);
  const { payment_details } = useSelector((state) => state?.payment);
  const [rowId, setRowId] = useState();
  const [displayData, setDisplayData] = useState([]);
  const [updateData, setUpdateData] = useState({});
  const [procedureLength, setProcedureLength] = useState(0);
  const [loadingData, setLoadingData] = useState(true);
  const [check, setCheck] = useState(true);
  const { id, page } = useParams();
  const {
    referringIndividual,
    selectReferringIndividual,
    referringInstitution,
    selectReferringInstitution,
    selectPayingSource,
    institutionName,
    payingSource,
    selectInstitutionName,
    packages,
    selectPackage,
    selectAuthorizationCode,
    selectEnrolleeID,
    visitNote,
    loading,
    handleReferralIndividualChange,
    handleReferringInstitutionChange,
    handlePayingSourceChange,
    handleInstitutionChange,
    handleSelectedPackage,
    handleAuthorizationCode,
    handleEnrolleeID,
    setVisitNote,
    setLoading
  } = useEditNewVisit();

  const payingSourceOption = selectPayingSource?.map((item) => ({
    label: item?.name,
    value: item?.name,
    code: item?.code
  }));
  const InstitutionNameOption = institutionName?.map((item) => ({
    label: item?.name,
    value: item?.code
  }));
  const ReferringInstitutionOption = referringInstitution?.map((item) => ({
    label: item?.name,
    value: item?.id
  }));
  const ReferralIndividualOption = referringIndividual?.map((item) => ({
    label: item?.full_name,
    value: item?.id
  }));
  const PackageOption = packages?.map((item) => ({
    label: item?.name,
    value: item?.id
  }));
  const referralIndividualName = selectReferringIndividual?.label;
  const referralIndividualID = selectReferringIndividual?.value;
  const referralInstitutionName = selectReferringInstitution?.label;
  const referralInstitutionID = selectReferringInstitution?.value;
  const paymentMethod = payingSource?.value;
  const payingInstitutionID = selectInstitutionName?.value;
  const enrolleeData = selectEnrolleeID;
  const authoriziseData = selectAuthorizationCode;
  const packageID = selectPackage?.value;
  const referringInstitutionDataNull =
    referralInstitutionID === null || referralInstitutionID === undefined;

  const paymentAction = () => {
    setLoadingData(true);
    dispatch(
      getPaymentDetailsAction(
        { id: id },
        (res) => {
          setUpdateData(res);
          setProcedureLength(res?.procedures?.length);
          setLoadingData(false);
        },
        () => {
          setLoadingData(false);
        }
      )
    );
  };
  useEffect(() => {
    paymentAction();
  }, []);
  let arrayLength = updateData?.procedures?.length > procedureLength;
  let arrayLength1 = updateData?.procedures?.length < procedureLength;
  let packageCheck = updateData?.package_id !== packageID;
  useEffect(() => {
    if (arrayLength || arrayLength1) {
      setCheck(false);
    }
  }, [updateData?.procedures?.length]);
  useEffect(() => {
    if (packageCheck) {
      setUpdateData({ ...updateData, procedures: [] });
    }
  }, [packageID]);
  const paymentMethodCheck =
    paymentMethod !== payment_details?.paying_source ||
    paymentMethod === payment_details?.paying_source;
  useEffect(() => {
    if (paymentMethodCheck) {
      setCheck(true);
    }
  }, [paymentMethod]);

  const handleOpenModal = (row) => {
    setDeleteModal(true);
    setRowId(row);
  };

  const openModal = () => {
    if (packageID) {
      setPackageModal(!openPackageModal);
    } else {
      setProcedureModal(!openProcedureModal);
    }
  };
  const handleDelete = () => {
    setLoadingData(true);
    dispatch(
      deleteProcedureRecordAction(
        { id: rowId?.record_id },
        () => {
          setLoadingData(false);
          toast.success('Record has been deleted successfully');
          setDeleteModal(false);
          navigate(`/front-desk/patient-details/${id}/${page}`);
        },
        (error) => {
          setLoadingData(false);
          toast.error(error);
        }
      )
    );
  };
  const handleSubmit = () => {
    setLoadingData(true);
    setLoading(true);
    const withPackage = updateData?.procedures?.map((el) => ({
      test_id: el?.test_id,
      scheduled_date: el?.scheduled_date,
      package_id: el?.package_id,
      room: el?.room,
      record_id: el?.record_id
    }));
    const withOutPackage = updateData?.procedures?.map((el) => ({
      test_id: el?.test_id,
      price: Number(el?.amount),
      scheduled_date: el?.scheduled_date,
      room: el?.room,
      record_id: el?.record_id
    }));
    const summaryStatus = updateData?.package_id;
    const procedureSummary = summaryStatus ? withPackage : withOutPackage;
    const constantData = {
      referee_id: referralIndividualID,
      payment_method: paymentMethod,
      procedures: procedureSummary,
      visit_notes: visitNote
    };
    const selectSelfOutOfPocket = {
      ...constantData
    };
    const selectReferrerOutOfPocket = {
      ...constantData,
      referring_institution: referralInstitutionID
    };
    const selecteReferrerHMOWithOrWithoutPackage = {
      ...constantData,
      paying_institution: payingInstitutionID,
      referring_institution: referralInstitutionID,
      enrollee_id: enrolleeData,
      authorization_code: authoriziseData
    };
    const selecteReferrerHealthFacilitiesBusinessWithOrWithoutPackage = {
      ...constantData,
      paying_institution: payingInstitutionID,
      referring_institution: referralInstitutionID
    };
    const selectSelfHMOWithOrWithoutPackage = {
      ...constantData,
      paying_institution: payingInstitutionID,
      enrollee_id: enrolleeData,
      authorization_code: authoriziseData
    };
    const selectSelfHealthFacilitiesBusinessWithOrWithoutPackage = {
      ...constantData,
      paying_institution: payingInstitutionID
    };
    const toggleOutOfPocket = referringInstitutionDataNull
      ? selectSelfOutOfPocket
      : selectReferrerOutOfPocket;
    const toggleReferrerHMOHealthFacilitiesBusiness = enrolleeData
      ? selecteReferrerHMOWithOrWithoutPackage
      : selecteReferrerHealthFacilitiesBusinessWithOrWithoutPackage;
    const toggleSelfHMOHealthFacilitiesBusiness =
      enrolleeData && payingInstitutionID
        ? selectSelfHMOWithOrWithoutPackage
        : selectSelfHealthFacilitiesBusinessWithOrWithoutPackage;

    const toggleBtwReferalOutOfPocket =
      payingInstitutionID && referralInstitutionID
        ? toggleReferrerHMOHealthFacilitiesBusiness
        : toggleOutOfPocket;
    const toggleData =
      referringInstitutionDataNull && payingInstitutionID
        ? toggleSelfHMOHealthFacilitiesBusiness
        : toggleBtwReferalOutOfPocket;
    const id = updateData?.id;

    dispatch(
      editPatientVisitAction(
        {
          id,
          data: toggleData
        },
        () => {
          toast.success('Patient visit updated successfully');
          navigate(`/front-desk/patient-details/${id}/${page}`);
          paymentAction();
          setLoadingData(false);
        },
        (err) => {
          toast.error(err);
          setLoadingData(false);
        }
      )
    );
  };

  return (
    <div className={styles.layout}>
      <div>
        <Formik
          initialValues={{
            referringInstitutions: '',
            referringIndividual: '',
            payingSource: '',
            payingInstitution: '',
            package: '',
            authorizationCode: '',
            enrolleeID: '',
            visitNote: ''
          }}>
          {(formik) => (
            <div>
              <Form>
                <div className={styles.layout__referralFormWrapper}>
                  <div>
                    <ReactSelect
                      label="Referring Individual"
                      loading={loading}
                      placeholder="Search"
                      isSearchable
                      onChange={(e) => handleReferralIndividualChange(e)}
                      options={ReferralIndividualOption}
                      name="referringIndividual"
                      isClearable
                      value={selectReferringIndividual}
                    />
                  </div>
                  {selectReferringIndividual?.label === 'Self' ? (
                    ''
                  ) : (
                    <div className={styles.formWrapper__input}>
                      <ReactSelect
                        label="Referring Institution"
                        loading={loading}
                        placeholder="Search"
                        isSearchable
                        onChange={(e) => handleReferringInstitutionChange(e)}
                        options={ReferringInstitutionOption}
                        name="referringInstitutions"
                        isClearable
                        value={selectReferringInstitution}
                      />
                    </div>
                  )}
                  <div className={styles.formWrapper__input}>
                    <ReactSelect
                      label="Paying Source"
                      loading={loading}
                      placeholder="Search"
                      isSearchable
                      onChange={(e) => handlePayingSourceChange(e)}
                      options={payingSourceOption}
                      name="payingSource"
                      isClearable
                      value={payingSource}
                    />
                  </div>
                  {!payingSource?.value?.length || payingSource?.value === 'out of pocket' ? (
                    ''
                  ) : (
                    <div className={styles.formWrapper__input}>
                      <ReactSelect
                        label="Paying Institution"
                        loading={loading}
                        placeholder="Search"
                        isSearchable
                        onChange={(e) => handleInstitutionChange(e)}
                        options={InstitutionNameOption}
                        name="payingInstitution"
                        isClearable
                        value={selectInstitutionName}
                      />
                    </div>
                  )}
                  <div className={styles.formWrapper__input}>
                    <ReactSelect
                      label="Package"
                      loading={loading}
                      placeholder="Select Package"
                      isSearchable
                      onChange={(e) => handleSelectedPackage(e)}
                      options={PackageOption}
                      name="package"
                      isClearable
                      value={selectPackage}
                    />
                  </div>
                  {!payingSource?.value?.length ||
                  payingSource?.value === 'out of pocket' ||
                  payingSource?.value === 'BUSINESS' ||
                  payingSource?.value === 'HEALTH-FACILITIES' ? (
                    ''
                  ) : (
                    <div className={styles.formWrapper__flexInput}>
                      <div>
                        <FormInput
                          placeholder="Enter Authorization Code"
                          label="Authorization Code"
                          name="authorizationCode"
                          onChange={(e) => handleAuthorizationCode(e)}
                          value={selectAuthorizationCode}
                        />
                      </div>
                      <div>
                        <FormInput
                          placeholder="Enter Enrollee ID*"
                          label="Enrollee ID"
                          name="enrolleeID"
                          onChange={(e) => handleEnrolleeID(e)}
                          value={selectEnrolleeID}
                        />
                        {formik.errors.enrolleeID ? (
                          <span className="error">{formik.errors.enrolleeID}</span>
                        ) : null}
                      </div>
                    </div>
                  )}

                  <div className={styles.formWrapper__input}>
                    <p className={styles.formWrapper__label}>Visit Note</p>
                    <textarea
                      className={styles.formWrapper__textarea}
                      placeholder="enter visit note..."
                      name="visitNote"
                      value={visitNote}
                      onChange={(e) => setVisitNote(e?.target?.value)}
                    />
                  </div>
                  {selectPackage?.value && (
                    <div className={styles.formWrapper__button}>
                      <Button
                        theme="orange"
                        disabled={
                          selectAuthorizationCode?.length === 0 || selectEnrolleeID?.length === 0
                        }
                        size="sm"
                        onClick={() => openModal()}>
                        View Package Procedures
                      </Button>{' '}
                    </div>
                  )}
                </div>
                <div className={styles.procedureSummary}>
                  <h3>Procedure Summary</h3>
                  <div>
                    <Table
                      headers={singleQueueHeaders}
                      tableData={updateData?.procedures}
                      placeholderText="Procedure"
                      emptyState
                      loading={loadingData}>
                      {(row) => {
                        const awaitingResult =
                          row?.procedure_status === 'awaiting_result'
                            ? row.procedure_status
                            : row.status;

                        const resultReady =
                          row.status === 'result_ready' ? 'result ready' : awaitingResult;
                        return (
                          <>
                            <td>₦{priceSplitter(row?.amount || 0)}</td>
                            <td>{row?.room || '-'}</td>
                            <td>{row?.name || '-'}</td>
                            <td>{FormatDate(row?.scheduled_date || '-')}</td>
                            <td>
                              <span>
                                <Indicator
                                  isActive={
                                    row?.procedure_status === 'transferred'
                                      ? row?.procedure_status
                                      : awaitingResult
                                  }
                                />
                                &nbsp;
                                {row?.procedure_status === 'transferred'
                                  ? row?.procedure_status
                                  : resultReady || '-'}
                              </span>
                            </td>
                            <td>
                              <Dropdown
                                content={
                                  <>
                                    <Option onClick={() => handleOpenModal(row)}>
                                      <p>Delete</p>
                                    </Option>
                                  </>
                                }>
                                <img src={Dots} alt="dot" />
                              </Dropdown>{' '}
                            </td>
                          </>
                        );
                      }}
                    </Table>
                  </div>
                  {!selectPackage?.value && (
                    <div className={styles.procedureSummary__AddButton}>
                      <Button theme="orange_plain" size="sm" onClick={() => openModal()}>
                        Add Procedure
                      </Button>{' '}
                    </div>
                  )}
                  <div className={styles.procedureSummary__footer}>
                    <div></div>
                    <div>
                      <Button
                        theme="orange"
                        size="sm"
                        loading={loading}
                        onClick={() => handleSubmit()}
                        disabled={
                          payingSource?.value === null ||
                          (payingSource?.value === 'HMO' &&
                            selectAuthorizationCode?.length === 0) ||
                          selectEnrolleeID?.length === 0 ||
                          updateData?.procedures?.length === 0
                            ? // ||
                              // check
                              formik.isValid || !formik.dirty
                            : ''
                        }>
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>{' '}
            </div>
          )}
        </Formik>
      </div>
      {deleteModal && (
        <DeleteConfirmationModal
          open={deleteModal}
          close={() => setDeleteModal(false)}
          onCancel={() => setDeleteModal(false)}
          onDelete={() => handleDelete()}
          title="Procedure"
          loading={loadingData}
        />
      )}
      {openProcedureModal && (
        <ChooseProcedureModal
          open={openProcedureModal}
          onClick={() => setProcedureModal(!openProcedureModal)}
          updateData={updateData && updateData}
          setUpdateData={setUpdateData}
          referralIndividualName={referralIndividualName}
          referralIndividualID={selectReferringIndividual?.value}
          referralInstitutionName={referralInstitutionName}
          referralInstitutionID={selectReferringInstitution?.value}
          paymentMethod={payingSource?.value}
          payingInstitutionID={selectInstitutionName?.value}
          packageID={selectPackage?.value}
          enrolleeData={selectEnrolleeID}
          authoriziseData={selectAuthorizationCode}
          setProcedureModal={setProcedureModal}
          setCheck={setCheck}
        />
      )}
      {openPackageModal && packageID && (
        <PackagesModal
          open={openPackageModal}
          isClose={() => setPackageModal(!openPackageModal)}
          onClick={() => setPackageModal(!openPackageModal)}
          updateData={updateData}
          setUpdateData={setUpdateData}
          packageID={packageID}
          referralIndividualName={referralIndividualName}
          referralIndividualID={selectReferringIndividual?.value}
          referralInstitutionName={referralInstitutionName}
          referralInstitutionID={selectReferringInstitution?.value}
          paymentMethod={payingSource?.value}
          payingInstitutionID={selectInstitutionName?.value}
          enrolleeData={selectEnrolleeID}
          authoriziseData={selectAuthorizationCode}
          setPackageModal={setPackageModal}
          setDisplayData={setDisplayData}
          setCheck={setCheck}
          displayData={displayData}
        />
      )}
    </div>
  );
};

export default EditVisitRefferalSourceForm;
