import { API } from '../../const';
import { put, takeEvery, call } from 'redux-saga/effects';
import api from '../../services/api';
import roleTypes from './roles.types';
import queryFormatter from 'helpers/queryFormatter';

export function* getRoles(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.ROLEDETAIL}?${queryFormatter(data.params)}`));
    if (res.status === 200) {
      yield put({ type: roleTypes.SET_ROLES, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getPermissions(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.ROLE}/permissions?type=${data.type}`));
    if (res.status === 200) {
      yield put({ type: roleTypes.SET_PERMISSIONS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* doCreateRole(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`${API.ROLE}`, data));
    if (res.status === 201) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getSingleRole(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.ROLE}/${data.id}`));
    if (res.status === 200) {
      yield put({ type: roleTypes.SET_SINGLE_ROLE, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* editSingleRole(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.put(`${API.ROLE}/${data.id}`, data?.data));
    if (res.status === 200) {
      yield put({ type: roleTypes.SET_EDITED_ROLE, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* watchRoleSaga() {
  yield takeEvery(roleTypes.GET_ROLES, getRoles);
  yield takeEvery(roleTypes.GET_PERMISSIONS, getPermissions);
  yield takeEvery(roleTypes.CREATE_ROLE, doCreateRole);
  yield takeEvery(roleTypes.GET_SINGLE_ROLE, getSingleRole);
  yield takeEvery(roleTypes.GET_EDITED_ROLE, editSingleRole);
}
