import { API } from 'const';
import { put, takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import queryFormatter from 'helpers/queryFormatter';
import clinicianType from './clinicians.types';

export function* getClinicianWaitList(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.PATIENT}/waitlist/clinician?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: clinicianType.SET_PATIENTS_PROCEDURES, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getPatientHistoryByRoom(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.PATIENT}/patient-history/clinician?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: clinicianType.SET_PATIENTS_VISITS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getSinglePatientHistory(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.PATIENT}/${data.id}/patient-tests/clinician?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: clinicianType.SET_SINGLE_VISIT, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getTestComponent(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.SETTINGS}/test/component?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: clinicianType.SET_TEST_COMPONENT, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getPatientTestResults(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.PATIENT}/tests-results/clinician?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: clinicianType.SET_TEST_RESULT, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getSinglePatientVisitTest(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.PATIENT}/${data.id}/visit-tests/clinician?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: clinicianType.SET_TEST_DETAILS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* doCheckInPatient(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.patch(`${API.PATIENT}/check-in`, data));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* doTransferTest(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.patch(`${API.PATIENT}/transfer-test`, data));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getIncomingPatients(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.PATIENT}/waitlist/incoming-patients?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getIncomingTests(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.PATIENT}/incoming-tests/clinician?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* watchClinicianSaga() {
  yield takeEvery(clinicianType.GET_PATIENTS_PROCEDURES, getClinicianWaitList);
  yield takeEvery(clinicianType.GET_PATIENTS_VISITS, getPatientHistoryByRoom);
  yield takeEvery(clinicianType.GET_SINGLE_VISIT, getSinglePatientHistory);
  yield takeEvery(clinicianType.GET_TEST_COMPONENT, getTestComponent);
  yield takeEvery(clinicianType.GET_TEST_RESULT, getPatientTestResults);
  yield takeEvery(clinicianType.GET_TEST_DETAILS, getSinglePatientVisitTest);
  yield takeEvery(clinicianType.CHECK_IN_PATIENT, doCheckInPatient);
  yield takeEvery(clinicianType.TRANSFER_TEST, doTransferTest);
  yield takeEvery(clinicianType.GET_INCOMING_PATIENTS, getIncomingPatients);
  yield takeEvery(clinicianType.GET_INCOMING_TESTS, getIncomingTests);
}

export default watchClinicianSaga;
