import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from 'router/PrivateRoute';
export const InvoicesPage = lazy(() => import('pages/Invoices/InvoicesPage'));

export default function InvoicesRoutes() {
  return (
    <Routes>
      <Route
        path="/invoices"
        element={
          <PrivateRoute>
            <InvoicesPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
