const partnerTypes = {
  CREATE_PARTNER: 'CREATE_PARTNER',
  GET_PARTNERSHIP_TYPE: 'GET_PARTNERSHIP_TYPE',
  CREATE_PARTNERS: 'CREATE_PARTNERS',
  GET_ALL_PARTNERS: 'GET_ALL_PARTNERS',
  GET_PARTNERS_PACKAGE: 'GET_PARTNERS_PACKAGE',
  GET_PARTNER_DETAILS: 'GET_PARTNER_DETAILS',
  EDIT_PARTNER_DETAILS: 'EDIT_PARTNER_DETAILS',
  DELETE_PARTNER: 'DELETE_PARTNER',
  UPDATE_PARTNER_STATUS: 'UPDATE_PARTNER_STATUS',
  GET_BILLING_OFFICER_PARTNER: 'GET_BILLING_OFFICER_PARTNER',
  GET_BILLING_PARTNER_DETAILS: 'GET_BILLING_PARTNER_DETAILS',
  CREATE_PARTNER_PAYMENT: 'CREATE_PARTNER_PAYMENT',
  CREATE_INVOICE: 'CREATE_INVOICE',
  GET_INVOICE_DETAILS: 'GET_INVOICE_DETAILS',
  GET_ALL_PACKAGE: 'GET_ALL_PACKAGE',
  PARTNER_VISIT_TRANSACTIONS: 'PARTNER_VISIT_TRANSACTIONS'
};

export default partnerTypes;
