import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

export const RebatePage = lazy(() => import('pages/Rebate/Rebate'));
export const ScheduleDetailsPage = lazy(() => import('container/Rebate/ScheduleDetails'));
export const ReferrerDetailsPage = lazy(() =>
  import('container/Rebate/ScheduleDetails/ReferrerDetails')
);

export default function RebateRoutes() {
  return (
    <Routes>
      <Route path="/rebate" element={<RebatePage />} />
      <Route path="/rebate/schedule/:id/:page" element={<ScheduleDetailsPage />} />
      <Route
        path="/rebate-referrer/:id/:page/:schedulepage/:scheduleid"
        element={<ReferrerDetailsPage />}
      />
    </Routes>
  );
}
