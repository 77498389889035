import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from 'router/PrivateRoute';

export const DashboardPage = lazy(() => import('pages/Dashboard/Dashboard'));
export const BillingDashboardPage = lazy(() => import('pages/Billing/BillingDashboard'));
export const FrontDeskPage = lazy(() => import('pages/FrontDesk/FrontDesk'));

export default function DashboardRoutes() {
  return (
    <Routes>
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/billing"
        element={
          <PrivateRoute>
            <BillingDashboardPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/front-desk"
        element={
          <PrivateRoute>
            <FrontDeskPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
