import { API } from '../../const';
import { put, takeEvery, call } from 'redux-saga/effects';
import api from '../../services/api';
import patientTypes from './patient.types';
import queryFormatter from '../../helpers/queryFormatter';
import axios from '../../services/axios';

export function* getPatients(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.PATIENTBRANCH}/${data.id}?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: patientTypes.SET_PATIENTS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getAllPatients(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.PATIENT}?${queryFormatter(data.params)}`));
    if (res.status === 200) {
      yield put({ type: patientTypes.SET_ALL_PATIENTS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getSinglePatient(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.PATIENT}/${data.id}/records?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: patientTypes.SET_SINGLE_PATIENT, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* createPatient(action) {
  try {
    const { cb, payload } = action;
    const res = yield call(() => api.post(API.PATIENT, payload));
    if (res.status === 201) {
      yield put({ type: patientTypes.SET_PATIENT, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || err?.response?.data?.errors || '';
    cbError(alert);
  }
}

export function* editPatient(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.put(`${API.PATIENT}/${data.id}`, data.payload));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getBranchPatients(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.BRANCHPATIENTSRECORD}?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: patientTypes.SET_BRANCH_PATIENTS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* doValidatePatientOTP(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.post(API.VALIDATE_PATIENT_OTP, data));
    if (res.status === 200) {
      axios.defaults.headers.common.Authorization = res.data.data.token;
      cb(res.data.data);
    }
  } catch (error) {
    const { cbError } = action;
    const alert = error?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getReferral(action) {
  try {
    const { cb } = action;
    const res = yield call(() => api.get(API.REFERRAL));
    if (res.status === 200) {
      yield put({ type: patientTypes.SET_REFERRAL, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getProcedures(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.PROCEDURES}/${data.branch_id}?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: patientTypes.SET_PROCEDURES, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getPriceTypes(action) {
  try {
    const { cb } = action;
    const res = yield call(() => api.get(API.PRICE_TYPES));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getCreateAppointment(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(API.CREATEAPPOINTMENT, data));
    if (res.status === 201) {
      yield put({ type: patientTypes.SET_APPOINTMENT, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getPackageDetails(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(API.MULTIPLEPACKAGE, data.params));
    if (res.status === 200) {
      yield put({ type: patientTypes.SET_PACKAGE_DETAILS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getBranchDetails(action) {
  try {
    const { cb, id } = action;
    const res = yield call(() => api.get(`${API.BRANCH}/${id}`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* completePatientRecord(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.patch(`${API.PATIENT}/records`, data));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* deletePatient(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.delete(`${API.PATIENT}/delete/${data?.id}?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* editPatientVisits(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.patch(`${API.PATIENT}/edit-visit/${data.id}`, data?.data));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* deletePatientVisit(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.delete(`${API.PATIENT}/delete-visit/${data?.id}`));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* deleteProcedureRecord(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.delete(`${API.PATIENT}/delete-record/${data?.id}`));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* watchPatientSaga() {
  yield takeEvery(patientTypes.GET_PATIENTS, getPatients);
  yield takeEvery(patientTypes.CREATE_PATIENT, createPatient);
  yield takeEvery(patientTypes.EDIT_PATIENT, editPatient);
  yield takeEvery(patientTypes.GET_SINGLE_PATIENT, getSinglePatient);
  yield takeEvery(patientTypes.GET_BRANCH_PATIENTS, getBranchPatients);
  yield takeEvery(patientTypes.GET_ALL_PATIENTS, getAllPatients);
  yield takeEvery(patientTypes.VALIDATE_PATIENT_OTP, doValidatePatientOTP);
  yield takeEvery(patientTypes.GET_REFERRAL, getReferral);
  yield takeEvery(patientTypes.GET_PROCEDURES, getProcedures);
  yield takeEvery(patientTypes.GET_APPOINTMENT, getCreateAppointment);
  yield takeEvery(patientTypes.GET_PACKAGE_DETAILS, getPackageDetails);
  yield takeEvery(patientTypes.GET_SINGLE_BRANCH, getBranchDetails);
  yield takeEvery(patientTypes.PRICE_TYPES, getPriceTypes);
  yield takeEvery(patientTypes.COMPLETE_PATIENT_RECORD, completePatientRecord);
  yield takeEvery(patientTypes.DELETE_PATIENT, deletePatient);
  yield takeEvery(patientTypes.EDIT_PATIENT_VISIT, editPatientVisits);
  yield takeEvery(patientTypes.DELETE_PATIENT_VISIT, deletePatientVisit);
  yield takeEvery(patientTypes.DELETE_PROCEDURE_RECORD, deleteProcedureRecord);
}
