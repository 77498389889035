import patientTypes from './patient.types';

export const getPatientsAction = (payload, cb, cbError) => ({
  type: patientTypes.GET_PATIENTS,
  payload,
  cb,
  cbError
});

export const getAllPatientsAction = (payload, cb, cbError) => ({
  type: patientTypes.GET_ALL_PATIENTS,
  payload,
  cb,
  cbError
});

export const getSinglePatientAction = (payload, cb, cbError) => ({
  type: patientTypes.GET_SINGLE_PATIENT,
  payload,
  cb,
  cbError
});

export const createPatientAction = (payload, cb, cbError) => ({
  type: patientTypes.CREATE_PATIENT,
  payload,
  cb,
  cbError
});

export const editPatientAction = (payload, cb, cbError) => ({
  type: patientTypes.EDIT_PATIENT,
  payload,
  cb,
  cbError
});

export const getBranchPatientsAction = (payload, cb, cbError) => ({
  type: patientTypes.GET_BRANCH_PATIENTS,
  payload,
  cb,
  cbError
});

export const doValidatePatientOTPAction = (payload, cb, cbError) => ({
  type: patientTypes.VALIDATE_PATIENT_OTP,
  payload,
  cb,
  cbError
});

export const getReferralAction = (payload, cb, cbError) => ({
  type: patientTypes.GET_REFERRAL,
  payload,
  cb,
  cbError
});

export const getProceduresAction = (payload, cb, cbError) => ({
  type: patientTypes.GET_PROCEDURES,
  payload,
  cb,
  cbError
});

export const getPriceTypesAction = (payload, cb, cbError) => ({
  type: patientTypes.PRICE_TYPES,
  payload,
  cb,
  cbError
});

export const createAppointmentAction = (payload, cb, cbError) => ({
  type: patientTypes.GET_APPOINTMENT,
  payload,
  cb,
  cbError
});

export const getPackageDetailsAction = (payload, cb, cbError) => ({
  type: patientTypes.GET_PACKAGE_DETAILS,
  payload,
  cb,
  cbError
});

export const getSingleBranchAction = (id, cb, cbError) => ({
  type: patientTypes.GET_SINGLE_BRANCH,
  id,
  cb,
  cbError
});

export const completePatientRecordAction = (payload, cb, cbError) => ({
  type: patientTypes.COMPLETE_PATIENT_RECORD,
  payload,
  cb,
  cbError
});

export const deletePatientAction = (payload, cb, cbError) => ({
  type: patientTypes.DELETE_PATIENT,
  payload,
  cb,
  cbError
});

export const editPatientVisitAction = (payload, cb, cbError) => ({
  type: patientTypes.EDIT_PATIENT_VISIT,
  payload,
  cb,
  cbError
});

export const deletePatientVisitAction = (payload, cb, cbError) => ({
  type: patientTypes.DELETE_PATIENT_VISIT,
  payload,
  cb,
  cbError
});

export const deleteProcedureRecordAction = (payload, cb, cbError) => ({
  type: patientTypes.DELETE_PROCEDURE_RECORD,
  payload,
  cb,
  cbError
});