import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

export const LoginPage = lazy(() => import('pages/Auth/LogIn'));
export const ForgotPasswordPage = lazy(() => import('pages/Auth/ForgotPassword'));
export const OTPPage = lazy(() => import('pages/Auth/OTP'));
export const ResetPasswordPage = lazy(() => import('pages/Auth/ResetPassword'));
export const SetPasswordPage = lazy(() => import('pages/Auth/SetPassword'));

export default function AuthRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/auth/sign-in" />} />
      <Route path="/auth/sign-in" element={<LoginPage />} />
      <Route path="/auth/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/auth/otp" element={<OTPPage />} />
      <Route path="/auth/reset-password" element={<ResetPasswordPage />} />
      <Route path="/auth/set-password" element={<SetPasswordPage />} />
    </Routes>
  );
}
