import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../PrivateRoute';

export const OnlineBookingsPage = lazy(() => import('pages/OnlineBookings/OnlineBookings'));
export const PatientBookingDetailsPage = lazy(() =>
  import('container/OnlineBookings/patientBookingDetails')
);
export const AddPatientToQueue = lazy(() => import('container/OnlineBookings/addPatientToQueue'));
export const ReferralSource = lazy(() => import('container/OnlineBookings/referralSource'));

export default function OnlineBookingsRoutes() {
  return (
    <Routes>
      <Route
        path="/bookings"
        element={
          <PrivateRoute>
            <OnlineBookingsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/bookings/:visit_enquiry_id/:payer/:page"
        element={
          <PrivateRoute>
            <PatientBookingDetailsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/bookings/add-to-queue/:visit_enquiry_id/:payer/:page"
        element={
          <PrivateRoute>
            <AddPatientToQueue />
          </PrivateRoute>
        }
      />
      <Route
        path="/bookings/referral-source/:visit_enquiry_id/:payer/:page"
        element={
          <PrivateRoute>
            <ReferralSource />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
