import visitTypes from './visit.types';

export const initialState = {
  visits: {}
};

const visitReducers = (state = initialState, action) => {
  switch (action.type) {
    case visitTypes.SET_VISIT_RECORDS:
      return {
        ...state,
        visits: action.payload
      };
    default:
      return state;
  }
};

export default visitReducers;
