import dashboardTypes from './adminDashboard.types';

export const getAllAdminDashboardAction = (payload, cb, cbError) => ({
  type: dashboardTypes.GET_ADMIN_DASHBOARD,
  payload,
  cb,
  cbError
});

export const getChannelsCollectedRevenueAction = (payload, cb, cbError) => ({
  type: dashboardTypes.GET_COLLECTED_REVENUES,
  payload,
  cb,
  cbError
});

export const getChannelsBookedRevenueAction = (payload, cb, cbError) => ({
  type: dashboardTypes.GET_BOOKED_REVENUES,
  payload,
  cb,
  cbError
});

export const getChannelsBookedRevenueDetailAction = (payload, cb, cbError) => ({
  type: dashboardTypes.GET_BOOKED_REVENUES_DETAILS,
  payload,
  cb,
  cbError
});

export const getChannelsCollectedRevenueDetailAction = (payload, cb, cbError) => ({
  type: dashboardTypes.GET_COLLECTED_REVENUES_DETAILS,
  payload,
  cb,
  cbError
});