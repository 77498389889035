import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from 'router/PrivateRoute';

export const RequestStatusPage = lazy(() => import('container/notifications/requestStatus'));
export const NotificationDetailsPage = lazy(() =>
  import('pages/Notifications/NotificationDetails')
);

export default function NotificationRoutes() {
  return (
    <Routes>
      <Route
        path="/notifications/request-status/:id"
        element={
          <PrivateRoute>
            <RequestStatusPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/notifications"
        element={
          <PrivateRoute>
            <NotificationDetailsPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
