import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from 'router/PrivateRoute';

export const PaymentPage = lazy(() => import('pages/Payments/Payment'));
export const PaymentDetailsPage = lazy(() => import('pages/Payments/PaymentDetails'));

export default function PaymentRoutes() {
  return (
    <Routes>
      <Route
        path="/payments"
        element={
          <PrivateRoute>
            <PaymentPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/payments/:id/:page"
        element={
          <PrivateRoute>
            <PaymentDetailsPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
