const onlineBookingTypes = {
  GET_ALL_ONLINE_BOOKING: 'GET_ALL_ONLINE_BOOKING',
  SET_ALL_ONLINE_BOOKING: 'SET_ALL_ONLINE_BOOKING',
  GET_ONLINE_BOOKING_DETAILS: 'GET_ONLINE_BOOKING_DETAILS',
  UPDATE_BOOKING_STATUS: 'UPDATE_BOOKING_STATUS',
  CREATE_VISIT_FROM_BOOKING: 'CREATE_VISIT_FROM_BOOKING',
  SAVE_VISIT_FROM_BOOKING: 'SAVE_VISIT_FROM_BOOKING'

};

export default onlineBookingTypes;
