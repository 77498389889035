/* eslint-disable no-case-declarations */

import reportTypes from './report.types';

export const initialState = {
  columns: [],
  data: [],
  single: [],
  reports: [],
  skipReset: true,
  tests: [],
  procedure: {},
  results: {},
  incoming_record: {}
};

const reportReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'add_data':
      return {
        ...state,
        data: action.payload,
        columns: action.columns
      };
    case 'update_column_header':
      const index = state.columns.findIndex((column) => column.id === action.columnId);
      return {
        ...state,
        skipReset: true,
        columns: [
          ...state.columns.slice(0, index),
          { ...state.columns[index], label: action.label },
          ...state.columns.slice(index + 1, state.columns.length)
        ]
      };
    case 'update_cell':
      return {
        ...state,
        skipReset: true,
        data: state.data.map((row, index) => {
          if (index === action.rowIndex) {
            return {
              ...state.data[action.rowIndex],
              [action.columnId]: {
                value: action.value,
                editable: action.editable
              }
            };
          }
          return row;
        })
      };
    case reportTypes.SET_PATIENT_RESULT:
      return {
        ...state,
        results: action.payload
      };
    case reportTypes.SET_INCOMING_PATIENT_RECORD:
      return {
        ...state,
        incoming_record: action.payload
      };
    default:
      return state;
  }
};

export default reportReducers;
