import React from 'react';
import styles from './table.module.scss';
import TableHeader from '../Table/TableHeader';
import TableBody from '../Table/TableBody';
import PropTypes from 'prop-types';

export const TableLoader = ({ headers }) => (
  <div className={styles.skeleton}>
    <div className={styles.skeleton__head} />
    {headers?.slice(0, 4).map((_, index) => (
      <div key={index} className={styles.skeleton__row}>
        {headers?.map((header, idx) => (
          <div key={idx} className={styles.skeleton__item} />
        ))}
      </div>
    ))}
  </div>
);

TableLoader.propTypes = {
  headers: PropTypes.array
};

const Table = ({
  headers,
  onClick,
  tableData,
  children,
  placeHolderImg,
  loading,
  placeholderText,
  emptyState,
  placeHolderCaption,
  onMouseEnter,
  onMouseLeave,
  onCheck
}) => {
  const sortRows = () => true;

  const table = (
    <>
      <>
        <table className={`${styles.table}`}>
          <TableHeader sortRows={sortRows} tableHeaders={headers} onCheck={(e) => onCheck(e)} />
          <TableBody
            cols={headers?.length}
            tableData={tableData}
            content={children}
            onClick={onClick}
            placeHolderImg={placeHolderImg}
            placeholderText={placeholderText}
            emptyState={emptyState}
            placeHolderCaption={placeHolderCaption}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        </table>
      </>
    </>
  );
  return loading ? <TableLoader headers={headers} /> : table;
};

Table.defaultProps = {
  currentPage: 1,
  totalPage: 10,
  changeCurrentPage: null,
  forcePage: false,
  loading: false
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.any),
  sortRows: PropTypes.any,
  placeHolderImg: PropTypes.node,
  tableData: PropTypes.arrayOf(PropTypes.any),
  children: PropTypes.any,
  cols: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  placeholderText: PropTypes.string,
  emptyState: PropTypes.bool,
  placeHolderCaption: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onCheck: PropTypes.any
};
export default Table;
