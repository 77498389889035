/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './input.module.scss';
import classnames from 'classnames';
import { ReactComponent as Closed } from '../../assets/icons/closedEye.svg';
import { ReactComponent as Opened } from '../../assets/icons/openedEye.svg';
import Loader from 'components/Loader';

const FormInput = (props) => {
  const {
    label,
    type = 'text',
    name,
    value,
    placeholder,
    onChange,
    onFocus,
    onBlur,
    className,
    disabled,
    id,
    children,
    dataTestId,
    pattern,
    defaultValue,
    verifiedCheck
  } = props;

  const [formType, setFormType] = useState(type);

  return (
    <>
      <label className={(styles.input__label, styles[`${type}`], className)} htmlFor={id}>
        {(label || children) && (
          <span className={styles.input__label}>
            {children}
            {label}
          </span>
        )}
        <input
          type={formType}
          value={value}
          onChange={onChange}
          name={name}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          className={classnames(styles.input)}
          placeholder={placeholder}
          pattern={pattern}
          data-testid={dataTestId}
          defaultValue={defaultValue}
        />
        <button
          className={styles.input__btn}
          type="button"
          data-testid="showpassword-button"
          aria-label="show password"
          onClick={() => setFormType(formType === 'password' ? 'text' : 'password')}>
          {type === 'password' && (
            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
              {}
              {formType === 'password' ? <Closed /> : <Opened />}
            </svg>
          )}
          {type !== 'password' && verifiedCheck && (
            <div>
              <Loader color="grey" />
            </div>
          )}
        </button>
      </label>
    </>
  );
};

FormInput.defaultProps = {
  type: null
};

FormInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  children: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string,
  pattern: PropTypes.string,
  defaultValue: PropTypes.string,
  verifiedCheck: PropTypes.bool
};

export default FormInput;
