import auditTypes from './audit.types';

export const getAllAuditsAction = (payload, cb, cbError) => ({
  type: auditTypes.GET_ALL_AUDITS,
  payload,
  cb,
  cbError
});

export const getAuditDetailsAction = (payload, cb, cbError) => ({
  type: auditTypes.GET_AUDIT_DETAILS,
  payload,
  cb,
  cbError
});

export const getAuditLogsAction = (payload, cb, cbError) => ({
  type: auditTypes.GET_AUDIT_LOGS,
  payload,
  cb,
  cbError
});
