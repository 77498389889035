import { API } from '../../const';
import { put, takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import queryFormatter from '../../helpers/queryFormatter';
import paymentTypes from './payment.types';

export function* getPaymentRecords(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.PATIENT}/payment/record?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getPaymentTracker(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.PATIENT}/visit/payment-record?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getPaymentDetails(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.PATIENT}/payment/${data.id}`));
    if (res.status === 200) {
      yield put({ type: paymentTypes.SAVE_PAYMENT_DETAILS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* editPayment(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.put(`${API.PATIENT}/record/update-price`, data));
    if (res.status === 200) {
      cb(res);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* recordPayment(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`${API.PATIENT}/record-payment`, data));
    if (res.status === 201) {
      cb(res);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* approveBonusRequest(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.patch(`${API.PATIENT}/approve-bonus-request`, data));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* sendBillToPartner(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`${API.PATIENT}/bill/partner-bills`, data));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* sendRecordsToClinician(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.patch(`${API.PATIENT}/visit/${data.id}/clinician`));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* requestDiscountApproval(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`${API.PATIENT}/discount/payment`, data));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getTransactionTab(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`/analytics/patient-transactions?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* createReceipt(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`${API.SETTINGS}/receipt`, data));
    if (res.status === 201) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getSingleReceipt(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.SETTINGS}/receipt/${data?.id}?${queryFormatter(data.params)}`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getPartnerPatientReceiptData(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.PARTNERS}/transaction-receipt/${data?.id}`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getPatientReceiptData(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`/analytics/patient-transaction/${data?.id}`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* watchPaymentSaga() {
  yield takeEvery(paymentTypes.GET_PAYMENT_RECORD, getPaymentRecords);
  yield takeEvery(paymentTypes.PAYMENT_TRACKER, getPaymentTracker);
  yield takeEvery(paymentTypes.PAYMENT_DETAILS, getPaymentDetails);
  yield takeEvery(paymentTypes.UPDATE_PAYMENT, editPayment);
  yield takeEvery(paymentTypes.RECORD_PAYMENT, recordPayment);
  yield takeEvery(paymentTypes.APPROVE_BONUS_REQUEST, approveBonusRequest);
  yield takeEvery(paymentTypes.SEND_PARTNER_BILL, sendBillToPartner);
  yield takeEvery(paymentTypes.SEND_RECORDS_TO_CLINICAN, sendRecordsToClinician);
  yield takeEvery(paymentTypes.DISCOUNT_APPROVAL, requestDiscountApproval);
  yield takeEvery(paymentTypes.GET_TRANSACTION_TAB, getTransactionTab);
  yield takeEvery(paymentTypes.CREATE_RECEIPT, createReceipt);
  yield takeEvery(paymentTypes.GET_SINGLE_RECEIPT, getSingleReceipt);
  yield takeEvery(paymentTypes.GET_PARTNER_PATIENT_RECEIPT_DATA, getPartnerPatientReceiptData);
  yield takeEvery(paymentTypes.GET_PATIENT_RECEIPT_DATA, getPatientReceiptData);
}

export default watchPaymentSaga;
