const referralTypes = {
  CREATE_REFERRAL: 'CREATE_REFERRAL',
  FETCH_REFERRALS: 'FETCH_REFERRALS',
  SET_REFERRALS: 'SET_REFERRALS',
  EDIT_REFERRAL: 'EDIT_REFERRAL',
  FETCH_SINGLE_REFERRAL: 'FETCH_SINGLE_REFERRAL',
  SET_SINGLE_REFERRAL: 'SET_SINGLE_REFERRAL',
  FETCH_BANKS: 'FETCH_BANKS',
  SET_BANKS: 'SET_BANKS',
  GET_REFEREE_INSTITUTION: 'GET_REFEREE_INSTITUTION',
  GET_DUE_PAYMENT: 'GET_DUE_PAYMENT',
  SET_DUE_PAYMENT: 'SET_DUE_PAYMENT',
  GET_REFEREE_PAYMENT: 'GET_REFEREE_PAYMENT',
  SET_REFEREE_PAYMENT: 'SET_REFEREE_PAYMENT',
  RECORD_REFEREE_PAYMENT: 'RECORD_REFEREE_PAYMENT',
  GET_PAID_TRANSACTIONS: 'GET_PAID_TRANSACTIONS',
  SET_PAID_TRANSACTIONS: 'SET_PAID_TRANSACTIONS',
  GET_PAYMENT_DETAILS: 'GET_PAYMENT_DETAILS',
  SET_PAYMENT_DETAILS: 'SET_PAYMENT_DETAILS',
  DELETE_REFERRAL: 'DELETE_REFERRAL'
};

export default referralTypes;
