import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from 'router/PrivateRoute';

export const AuditPage = lazy(() => import('pages/Audit/Audit'));
export const AuditDetailsPage = lazy(() => import('container/AuditTrail/auditDetails'));
export const ViewLogsPage = lazy(() => import('container/AuditTrail/viewLogs'));

export default function AuditTrailRoutes() {
  return (
    <Routes>
      <Route
        path="/audit-trail"
        element={
          <PrivateRoute>
            <AuditPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/audit-trail/details/:id/:page"
        element={
          <PrivateRoute>
            <AuditDetailsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/audit-trail/view/logs/:id/:page"
        element={
          <PrivateRoute>
            <ViewLogsPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
