import React from 'react';
import { AppRouter } from './router';
import { ToastContainer } from 'react-toastify';
import { CustomBrowserRouter } from '../src/utils/customBrowserRouter';

function App() {
  return (
    <div>
      <CustomBrowserRouter>
        <AppRouter />
        <ToastContainer theme="colored" autoClose={3000}
/>
      </CustomBrowserRouter>
    </div>
  );
}

export default App;
