import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './frontdesk.module.scss';
import ReactSelect from '../../components/Select/ReactSelect';
import Modal from 'components/Modal/Modal';
import { getPriceTypesAction, getProceduresAction } from 're-ducks/patients';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import { ReactComponent as Close } from '../../assets/icons/closeModal.svg';
import Button from '../../components/Button/Button';

const ChooseProcedureModal = ({
  open,
  close,
  onClick,
  updateData,
  setUpdateData,
  setProcedureModal,
  referralIndividualID,
  referralInstitutionID,
  paymentMethod,
  payingInstitutionID,
  packageID,
  enrolleeData,
  authoriziseData,
}) => {
  const [selectProcedure, setSelectProcedure] = useState({
    label: '',
    value: '',
    price: '',
    room: ''
  });
  const [procedureOption, setProcedureOption] = useState([]);
  const [procedureArray, setProcedureArray] = useState([]);
  const [priceType, setPriceType] = useState([]);
  const [selectPriceType, setSelectPriceType] = useState();
  const priceTypeOption = priceType?.map((el) => ({ label: el?.name, value: el?.code }));
  const [loading, setLoading] = useState(false);
  const { auth } = useSelector((state) => state);
  const branchId = auth?.signIn?.branch_id;
  const dispatch = useDispatch();
  const procedureArrayTestId = procedureArray?.map((item) => item?.test_id);
  const updateDataTestId = updateData?.procedures?.map((item) => item?.test_id);

  const procedureOptions = procedureOption
    ?.map((item) => ({
      label: item?.name,
      value: item?.unique_id,
      room: item?.room,
      price: item?.price
    }))
    ?.filter(
      (d) => !procedureArrayTestId?.includes(d.value) && !updateDataTestId?.includes(d.value)
    );

    const regularId = priceTypeOption?.filter((i) => i.label === 'regular');

    useEffect(() => {
      setSelectPriceType({
        label: updateData?.price_type_name ? updateData?.price_type_name : 'regular',
        value:  updateData?.price_type_id ? updateData?.price_type_id : regularId[0]?.value
      })
    }, []);

  const handlePriceType = (e) => {
    setSelectPriceType(e);
    setLoading(true);
    dispatch(
      getProceduresAction(
        {
          branch_id: branchId,
          params: {
            price_type: e?.value
          }
        },
        (res) => {
          setProcedureOption(res);
          setLoading(false);
        },
        (error) => {
          toast.error(error);
          setLoading(false);
        }
      )
    );
  };
  let existingProcedureArray = updateData?.procedures;
  const constantData = {
    ...updateData,
    referee_id: referralIndividualID,
    referring_institution_id: referralInstitutionID,
    paying_source: paymentMethod,
    paying_institution_id: payingInstitutionID,
    package_id: packageID,
    enrollee_id: enrolleeData,
    authorization_code: authoriziseData
  };
  const handleNavigate = () => {
    setUpdateData(
      paymentMethod && updateData?.visit_type === 'regular_visit'
        ? {
            ...constantData,
            procedures: [...existingProcedureArray, ...procedureArray]
          }
        : {
            ...constantData,
            procedures: (existingProcedureArray = procedureArray)
          }
    ),
      setProcedureModal(false);
  };

  useEffect(() => {
    dispatch(
      getProceduresAction(
        {
          branch_id: branchId,
          params: {
            price_type: updateData?.price_type_name ? updateData?.price_type_name : 'regular'
          }
        },
        (res) => {
          setProcedureOption(res);
          setLoading(false);
        },
        (error) => {
          toast.error(error);
          setLoading(false);
        }
      )
    );
  }, []);
  
  useEffect(() => {
    setLoading(true);
    dispatch(
      getPriceTypesAction(
        {},
        (res) => {
          setPriceType(res);
          setLoading(false);
        },
        (error) => {
          toast.error(error);
          setLoading(false);
        }
      )
    );
  }, []);
  const handleClose = (test_id) => {
    setProcedureArray(procedureArray?.filter((item) => item?.test_id !== test_id));
  };
  const handleChange = (e) => {
    setSelectProcedure(e);
    setProcedureArray([
      ...procedureArray,
      {
        amount: e.price,
        test_id: e.value,
        name: e.label,
        room: e?.room,
        scheduled_date: moment.parseZone().format('YYYY-MM-DD HH:mm'),
        status: 'pending',
        record_id: existingProcedureArray?.record_id
      }
    ]);
  };

  const onChangeDate = (e, index) => {
    const allProcedureSelected = [...procedureArray];
    const selected = allProcedureSelected[index];
    selected.scheduled_date = e.target.value;
    setProcedureArray(allProcedureSelected);
  };
  return (
    <div>
      <Modal isClose={close} isOpen={open} width={1000}>
        <div className={styles.chooseProcedure__header}>
          <h2 className={styles.chooseProcedure__title}>Choose Procedures</h2>{' '}
          <div aria-hidden onClick={onClick} className={styles.icon}>
            <Close />
          </div>
        </div>
        <p className={styles.chooseProcedure__description}>
          Select a room from the dropdown and mark the procedures using the checkbox.
        </p>
        <div className={styles.chooseProcedure}>
          <div className={styles.chooseProcedure__selectContainer}>
            <div className={styles.chooseProcedure__priceTypeInput}>
              <ReactSelect
                label="Price Types"
                type="text"
                loading={loading}
                isSearchable
                placeholder="select"
                options={priceTypeOption}
                value={selectPriceType}
                onChange={(e) => handlePriceType(e)}
                name="pricetype"
                size="sm"
              />
            </div>
            <ReactSelect
              placeholder="Search"
              loading={loading}
              isSearchable
              onChange={(e) => handleChange(e)}
              options={procedureOptions}
              closeMenuOnSelect={false}
              name="price"
              value={selectProcedure}
            />{' '}
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <Button
                size="md_percent"
                theme="orange"
                onClick={() => handleNavigate()}
                disabled={!procedureArray?.length}
                loading={loading}>
                Continue
              </Button>
            </div>
          </div>
          <div className={styles.chooseProcedure__summary}>
            <h3>Procedures Summary</h3>
            {!procedureArray?.length ? (
              <p className={styles.priceCalculator__emptyState}>No Selected Option</p>
            ) : (
              <>
                <div className={styles.priceCalculator__summaryContainer}>
                  <div className={styles.priceCalculator__summaryContainer_flex}>
                    {procedureArray?.map((item, index) => {
                      return (
                        <div className={styles.priceCalculator__summaryItem} key={item?.test_id}>
                          <div>
                            {item?.name}
                            <br />
                            <span className={styles.priceCalculator__summaryItem__value}>
                              <input
                                type="datetime-local"
                                value={item?.date}
                                style={{ backgroundColor: '#edf2f7' }}
                                onChange={(e) => onChangeDate(e, index)}
                                min={moment.parseZone().format('YYYY-MM-DD HH:mm')}
                                defaultValue={moment.parseZone().format('YYYY-MM-DD HH:mm')}
                              />
                            </span>
                          </div>
                          <div
                            aria-hidden
                            onClick={() => handleClose(item?.test_id)}
                            className={styles.priceCalculator__summaryItem__icon}>
                            <Close width="19" height="19" alt="close-icon" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
ChooseProcedureModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  onClick: PropTypes.func,
  setUpdateData: PropTypes.func,
  updateData: PropTypes.object,
  setProcedureModal: PropTypes.func,
  referralIndividualID: PropTypes.string,
  referralInstitutionID: PropTypes.string,
  paymentMethod: PropTypes.string,
  payingInstitutionID: PropTypes.string,
  packageID: PropTypes.string,
  enrolleeData: PropTypes.string,
  authoriziseData: PropTypes.string,
  setCheck: PropTypes.bool
};
export default ChooseProcedureModal;
