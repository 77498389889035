import { API } from 'const';
import { put, takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import userTypes from './user.types';
import queryFormatter from 'helpers/queryFormatter';

export function* getAllUsers(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.USER}?${queryFormatter(data.params)}`));
    if (res.status === 200) {
      yield put({ type: userTypes.SET_ALL_USER, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export default function* watchUserSaga() {
  yield takeEvery(userTypes.GET_ALL_USER, getAllUsers);
}
