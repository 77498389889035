const dashboardTypes = {
  GET_ADMIN_DASHBOARD: 'GET_ADMIN_DASHBOARD',
  GET_COLLECTED_REVENUES: 'GET_COLLECTED_REVENUES',
  GET_BOOKED_REVENUES: 'GET_BOOKED_REVENUES',
  GET_BOOKED_REVENUES_DETAILS: 'GET_BOOKED_REVENUES_DETAILS',
  GET_COLLECTED_REVENUES_DETAILS: 'GET_COLLECTED_REVENUES_DETAILS',
  SET_COLLECTED_REVENUES: 'SET_COLLECTED_REVENUES',
  SET_BOOKED_REVENUES: 'SET_BOOKED_REVENUES',
  SET_BOOKED_REVENUES_DETAILS: 'SET_BOOKED_REVENUES_DETAILS',
  SET_COLLECTED_REVENUES_DETAILS: 'SET_COLLECTED_REVENUES_DETAILS',
};

export default dashboardTypes;
