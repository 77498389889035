import roleTypes from './roles.types';

export const getRolesAction = (payload, cb, cbError) => ({
  type: roleTypes.GET_ROLES,
  payload,
  cb,
  cbError
});

export const getPermissionsAction = (payload, cb, cbError) => ({
  type: roleTypes.GET_PERMISSIONS,
  payload,
  cb,
  cbError
});

export const createRoleAction = (payload, cb, cbError) => ({
  type: roleTypes.CREATE_ROLE,
  payload,
  cb,
  cbError
});

export const fetchSingleRoleAction = (payload, cb, cbError) => ({
  type: roleTypes.GET_SINGLE_ROLE,
  payload,
  cb,
  cbError
});

export const editSingleRoleAction = (payload, cb, cbError) => ({
  type: roleTypes.GET_EDITED_ROLE,
  payload,
  cb,
  cbError
});
