import auditTypes from './audit.types';

export const initialState = {
  audits: [],
  audit_details: {},
  logs: []
};

const auditReducers = (state = initialState, action) => {
  switch (action.type) {
    case auditTypes.SET_ALL_AUDITS:
      return {
        ...state,
        audits: action.payload
      };
    case auditTypes.SET_AUDIT_DETAILS:
      return {
        ...state,
        audit_details: action.payload
      };
    case auditTypes.SET_AUDIT_LOGS:
      return {
        ...state,
        logs: action.payload
      };
    default:
      return state;
  }
};

export default auditReducers;
