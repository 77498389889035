const clinicianTypes = {
  GET_PATIENTS_PROCEDURES: 'GET_PATIENTS_PROCEDURES',
  SET_PATIENTS_PROCEDURES: 'SET_PATIENTS_PROCEDURES',
  GET_PATIENTS_VISITS: 'GET_PATIENTS_VISITS',
  SET_PATIENTS_VISITS: 'SET_PATIENTS_VISITS',
  GET_SINGLE_VISIT: 'GET_SINGLE_VISIT',
  SET_SINGLE_VISIT: 'SET_SINGLE_VISIT',
  GET_TEST_COMPONENT: 'GET_TEST_COMPONENT',
  SET_TEST_COMPONENT: 'SET_TEST_COMPONENT',
  PATIENT_TEST_SELECTED: 'PATIENT_TEST_SELECTED',
  GET_TEST_RESULT: 'GET_TEST_RESULT',
  SET_TEST_RESULT: 'SET_TEST_RESULT',
  GET_TEST_DETAILS: 'GET_TEST_DETAILS',
  SET_TEST_DETAILS: 'SET_TEST_DETAILS',
  CHECK_IN_PATIENT: 'CHECK_IN_PATIENT',
  TRANSFER_TEST: 'TRANSFER_TEST',
  GET_INCOMING_PATIENTS: 'GET_INCOMING_PATIENTS',
  GET_INCOMING_TESTS: 'GET_INCOMING_TESTS'
};

export default clinicianTypes;
