import adminDashboardTypes from './adminDashboard.types';

export const initialState = {
  collectedRevenues: [],
  bookedRevenues: [],
  bookedRevenuesDetails: [],
  collectedRevenuesDetails: []
};

export const adminDashboardReducers = (state = initialState, action) => {
  switch (action.type) {
    case adminDashboardTypes.SET_COLLECTED_REVENUES:
      return {
        ...state,
        collectedRevenues: action.payload
      };
    case adminDashboardTypes.SET_BOOKED_REVENUES:
      return {
        ...state,
        bookedRevenues: action.payload
      };
    case adminDashboardTypes.SET_BOOKED_REVENUES_DETAILS:
      return {
        ...state,
        bookedRevenuesDetails: action.payload
      };

      case adminDashboardTypes.SET_COLLECTED_REVENUES_DETAILS:
        return {
          ...state,
          collectedRevenuesDetails: action.payload
        };
    default:
      return state;
  }
};

export default adminDashboardReducers;
