import React from 'react';
import moment from 'moment';

export const FormatDate = (date) => {
  return <div>{moment.parseZone(date)?.format('MMMM DD, YYYY')}</div>;
};

export const FormatDateTimezone = (date) => {
  return (
    <div style={{ display: 'flex' }}>
      {moment.parseZone(date)?.format('MMMM DD, YYYY')} | &nbsp;
      <span style={{ color: '#81909D', textTransform: 'uppercase' }}>
        {moment.parseZone(date)?.format('h:mm a')}
      </span>
    </div>
  );
};

export const FormatDateRange = (date) => {
  return <div>{moment?.parseZone(date)?.format('MM-dd-yyyy')}</div>;
};

export const FormatDateOfBirth = (date) => {
  return <div>{moment?.parseZone(date)?.format('DD/MM/yyyy')}</div>;
};
