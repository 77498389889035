import React from 'react';
import PropTypes from 'prop-types';
import styles from './indicator.module.scss';
import classnames from 'classnames';

const Indicator = ({ isActive }) => {
  const activeClass = () => {
    if (isActive) {
      return styles[`indicator__${isActive}`];
    }
  };

  return (
    <span className={classnames(styles.indicator, activeClass(), `${styles[isActive]}`)}></span>
  );
};

Indicator.propTypes = {
  isActive: PropTypes.string,
  isProfileStatus: PropTypes.string,
  deactivated: PropTypes.bool,
  className: PropTypes.string
};

export default Indicator;
