import { API } from 'const';
import queryFormatter from 'helpers/queryFormatter';
import { put, call, takeEvery } from 'redux-saga/effects';
import api from 'services/api';
import dashboardTypes from './adminDashboard.types';

export function* getAdminDashboard(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.FETCH_ADMIN_DASHBOARD}?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getChannelsCollectedRevenue(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.ANALYTICS}/transaction-breakdown?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: dashboardTypes.SET_COLLECTED_REVENUES, payload: res.data.data });

      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getChannelsBookedRevenue(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.ANALYTICS}/booked-transaction-breakdown?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: dashboardTypes.SET_BOOKED_REVENUES, payload: res.data.data });
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getChannelsBookedRevenueDetails(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.ANALYTICS}/booked-revenue-details?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: dashboardTypes.SET_BOOKED_REVENUES_DETAILS, payload: res.data.data });
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getChannelsCollectedRevenueDetails(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(
        `${API.ANALYTICS}/revenue-details?branch_id=${data?.id}&${queryFormatter(data.params)}`
      )
    );
    if (res.status === 200) {
      yield put({ type: dashboardTypes.SET_COLLECTED_REVENUES_DETAILS, payload: res.data.data });
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}
function* watchAdminDashboardSaga() {
  yield takeEvery(dashboardTypes.GET_ADMIN_DASHBOARD, getAdminDashboard);
  yield takeEvery(dashboardTypes.GET_COLLECTED_REVENUES, getChannelsCollectedRevenue);
  yield takeEvery(dashboardTypes.GET_BOOKED_REVENUES, getChannelsBookedRevenue);
  yield takeEvery(dashboardTypes.GET_BOOKED_REVENUES_DETAILS, getChannelsBookedRevenueDetails);
  yield takeEvery(
    dashboardTypes.GET_COLLECTED_REVENUES_DETAILS,
    getChannelsCollectedRevenueDetails
  );
}

export default watchAdminDashboardSaga;
